import React, { useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { icon_bear, icon_insta, icon_linkedin, icon_youtube, LogoLight, red_dot } from '../../../assets/images';
import './FooterComponent.css';

import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';

const eventName = 'LP_FooterClicked';

interface FooterProps {
  parentPage: string;
  parentPage_linkId: string; 
}
const FooterComponent: React.FunctionComponent<FooterProps> = ({ parentPage, parentPage_linkId }) => {
  const { t } = useTranslation();
  const timerRef = useRef(null);

  const takeAction = async (userAction, link_text, link_url) => {
    if (parentPage_linkId !== userAction) {
      callPageClosed();
    }
    AnalyticsService.track(eventName, {
      action: SA[SA.Link_Clicked],
      linkId: userAction,
      linkText: link_text,
      linkUrl: link_url,
    });
    return null;
  };

  const handleLinkClick = async (e, url, action, link_text, isOut) => {
    e.preventDefault();
    await takeAction(action, link_text, url);
    if (isOut) {
      window.open(url, '_out');
    } else {
      window.location.href = url;
    }
  };

  const callPageClosed = () => {
    const timeSpent = (Date.now() - timerRef.current) / 1000;
    AnalyticsService.track(parentPage, {
      page: parentPage,
      action: SA[SA.Closed],
      duration: timeSpent, 
    });
  };

  useEffect(() => {
    timerRef.current = Date.now();
  }, []);
  return (
    <div className="Footer-Container-Wrapper ">
      <div className="mdSide:grid mdSide:grid-cols-2 lg:grid lg:grid-cols-2 gap-4 text-almond w-full mb-12">
        <div className="col-span-2 mb-8">
          <a
            onClick={(e) => handleLinkClick(e, '/#top', 'home_page', '', false)}
            style={{ cursor: 'pointer' }}
            className="whitespace-nowrap"
          >
            <img className="w-[3.313rem]" src={LogoLight} alt="Get the correct shoe size for your chosen shoe with Footprint" />
          </a>
        </div>
        <div className="mdSide:row-span-3 lg:row-span-3 mdSide:w-2/3 text-[1.875rem] md:text-[2.625rem] lg:text-[2.625rem] lg:w-[27.25rem] font-['Rubik'] font-bold">
          <Trans i18nKey="LP.footer.title"></Trans>
        </div>
        <div className="grid grid-cols-3 gap-x-20 lg:gap-x-30 gap-6 text-xl md:mt-12 md:mb-24 sm:hidden md:w-10/12 ">
          <div className="font-bold">
            <Trans i18nKey="LP.footer.product"></Trans>
          </div>
          <div className="font-bold">Footprint</div>
          <div className="font-bold">
            <Trans i18nKey="LP.footer.compliance"></Trans>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, '/Explore-Our-App#top', 'explore_app', t('menu.explore-our-app'), false)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.menu.explore-our-app"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/product/', 'about', t('menu.about-footprint'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.menu.about-footprint"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/contact/', 'contact', t('footer.contactUs'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.contactUs"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://help.myfootprint.app/', 'helpcenter', t('menu.help-center'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.menu.help-center"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/team/', 'team', t('footer.team'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.team"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a onClick={(e) => handleLinkClick(e, '/imprint', 'imprint', t('link.imprint'), true)} style={{ cursor: 'pointer' }}>
              <Trans i18nKey="LP.link.imprint"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap ">
            <a
              onClick={(e) => handleLinkClick(e, '/Shape-Footprint', 'create', t('menu.help-us-improve'), false)}
              style={{ cursor: 'pointer' }}
              className="flex"
            >
              <Trans i18nKey="LP.menu.help-us-improve"></Trans>
              <img src={red_dot} className="mb-4 ml-2" alt="red dot" />
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/climate/', 'planet', t('footer.planet'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.planet"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a onClick={(e) => handleLinkClick(e, '/privacy-policy', 'privacy', t('link.privacy'), true)} style={{ cursor: 'pointer' }}>
              <Trans i18nKey="LP.link.privacy"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap"></div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/resources/', 'press', t('footer.press'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.press"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap"></div>
          <div className="whitespace-nowrap"></div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/careers/', 'jobs', t('footer.jobs'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.jobs"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap"></div>
        </div>
        {/* MOBILE VIEW*/}
        <div className="grid gap-x-20 gap-4 text-xl md:hidden mdSide:hidden lg:hidden mb-24">
          <div className="font-bold mt-24 mdSide:mt-0 lg:mt-0">
            <Trans i18nKey="LP.footer.product"></Trans>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, '/Explore-Our-App#top', 'explore_app', t('link.how-it-works'), false)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.link.how-it-works"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://help.myfootprint.app/', 'helpcenter', t('menu.help-center'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.menu.help-center"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap  ">
            <a
              onClick={(e) => handleLinkClick(e, '/Shape-Footprint', 'create', t('menu.help-us-improve'), false)}
              style={{ cursor: 'pointer' }}
              className="flex"
            >
              <Trans i18nKey="LP.menu.help-us-improve"></Trans>
              <img src={red_dot} className="mb-4 ml-2" alt="red dot" />
            </a>
          </div>
          <div className="font-bold mt-8 mdSide:mt-0 lg:mt-0">
            <Trans i18nKey="LP.footer.company"></Trans>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/product/', 'about', t('menu.about-footprint'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.link.about"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/team/', 'team', t('footer.team'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.team"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/climate/', 'planet', t('footer.planet'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.planet"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/resources/', 'press', t('footer.press'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.press"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/careers/', 'jobs', t('footer.jobs'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.jobs"></Trans>
            </a>
          </div>
          <div className="font-bold mt-8 mdSide:mt-0 lg:mt-0">
            <Trans i18nKey="LP.footer.compliance"></Trans>
          </div>
          <div className="whitespace-nowrap">
            <a
              onClick={(e) => handleLinkClick(e, 'https://footprinttech.de/contact/', 'contact', t('footer.contactUs'), true)}
              style={{ cursor: 'pointer' }}
            >
              <Trans i18nKey="LP.footer.contactUs"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a onClick={(e) => handleLinkClick(e, '/imprint', 'imprint', t('link.imprint'), true)} style={{ cursor: 'pointer' }}>
              <Trans i18nKey="LP.link.imprint"></Trans>
            </a>
          </div>
          <div className="whitespace-nowrap">
            <a onClick={(e) => handleLinkClick(e, '/privacy-policy', 'privacy', t('link.privacy'), true)} style={{ cursor: 'pointer' }}>
              <Trans i18nKey="LP.link.privacy"></Trans>
            </a>
          </div>
          <div className="flex mt-8">
            <div className="flex flex-end gap-5 mr-8">
              <a
                onClick={(e) => handleLinkClick(e, 'https://www.linkedin.com/company/footprinttech/', 'linkedin', 'linkedin_icon', true)}
                style={{ cursor: 'pointer' }}
              >
                <img className="h-[1.573rem]" src={icon_linkedin} alt="linkedin" />
              </a>
              <a
                onClick={(e) =>
                  handleLinkClick(e, 'https://www.youtube.com/channel/UCEwcz3BUwVsTMuOV8JXdtTA', 'youtube', 'youtube_icon', true)
                }
                style={{ cursor: 'pointer' }}
              >
                <img className="h-[1.573rem]" src={icon_youtube} alt="youtube" />
              </a>
              <a
                onClick={(e) => handleLinkClick(e, 'https://www.instagram.com/myfootprint.shoes/', 'instagram', 'instagram_icon', true)}
                style={{ cursor: 'pointer' }}
              >
                <img className="h-[1.573rem]" src={icon_insta} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-span-2 text-regular mdSide:text-xl lg:text-xl flex gap-2 mb-8  mdSide:mb-2 lg:mb-2">
          Berlin <img src={icon_bear} alt="icon" /> Made
        </div>
        <div className="text-regular mdSide:text-xl lg:text-xl hidden lg:flex ">© 2019 - 2022 Footprint Technologies GmbH </div>
        <div className="flex-end justify-end sm:hidden   hidden lg:flex ">
          <div className="flex flex-end gap-5">
            <a
              onClick={(e) => handleLinkClick(e, 'https://www.linkedin.com/company/footprinttech/', 'linkedin', 'linkedin_icon', true)}
              style={{ cursor: 'pointer' }}
            >
              <img className="h-[1.573rem]" src={icon_linkedin} alt="linkedin" />
            </a>
            <a
              onClick={(e) =>
                handleLinkClick(e, 'https://www.youtube.com/channel/UCEwcz3BUwVsTMuOV8JXdtTA', 'youtube', 'youtube_icon', true)
              }
              style={{ cursor: 'pointer' }}
            >
              <img className="h-[1.573rem]" src={icon_youtube} alt="youtube" />
            </a>
            <a
              onClick={(e) => handleLinkClick(e, 'https://www.instagram.com/myfootprint.shoes/', 'instagram', 'instagram_icon', true)}
              style={{ cursor: 'pointer' }}
            >
              <img className="h-[1.573rem]" src={icon_insta} alt="instagram" />
            </a>
          </div>
        </div>

        <div className="flex w-full lg:hidden">
          <div className="text-regular mdSide:text-xl lg:text-xl whitespace-nowrap ">© 2019 - 2022 Footprint Technologies GmbH </div>
          <div className="md:w-5/12"></div>
          <div className="flex-end justify-end items-end sm:hidden flex">
            <div className="flex flex-end gap-5">
              <a
                onClick={(e) => handleLinkClick(e, 'https://www.linkedin.com/company/footprinttech/', 'linkedin', 'linkedin_icon', true)}
                style={{ cursor: 'pointer' }}
              >
                <img className="h-[1.573rem]" src={icon_linkedin} alt="linkedin" />
              </a>
              <a
                onClick={(e) =>
                  handleLinkClick(e, 'https://www.youtube.com/channel/UCEwcz3BUwVsTMuOV8JXdtTA', 'youtube', 'youtube_icon', true)
                }
                style={{ cursor: 'pointer' }}
              >
                <img className="h-[1.573rem]" src={icon_youtube} alt="youtube" />
              </a>
              <a
                onClick={(e) => handleLinkClick(e, 'https://www.instagram.com/myfootprint.shoes/', 'instagram', 'instagram_icon', true)}
                style={{ cursor: 'pointer' }}
              >
                <img className="h-[1.573rem]" src={icon_insta} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
