interface ExploreAppYuriProps {
  imageUrl?: string;
  imageAlign?: 'left' | 'right';
  children: React.ReactNode;
  showAnchor?: boolean;
}

const ExploreAppYuriComponent: React.FunctionComponent<ExploreAppYuriProps> = ({ imageUrl, children, imageAlign, showAnchor }) => {
  return (
    <>
      <div className="sm:hidden w-full">
        <div className="flex items-center justify-between mb-24 ">
          {imageAlign === 'right' ? (
            <>
              <div className="flex w-1/2">{children}</div>
              <div className="flex relative w-1/2 justify-start lg:ml-48 items-end md:items-start md:justify-start">
                <img src={imageUrl} className="md:w-full mdSide:w-[29.376rem]  lg:w-[29.586rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
            </>
          ) : (
            <>
              <div className="flex relative w-1/2 justify-start items-end md:items-start md:justify-start">
                <img src={imageUrl} className="md:w-full mdSide:w-[29.376rem]  lg:w-[29.586rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
              <div className="flex w-1/2 lg:ml-48">{children}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ExploreAppYuriComponent;
