import { useState } from 'react';
import { flag_de, flag_en } from '../../../assets/images';

interface LanguageSwitchProps {
  lang: string;
  onLanguageChange(lang: string): void;
}

const LanguageSwitchComponent: React.FunctionComponent<LanguageSwitchProps> = (props) => {
  const [lang, setLang] = useState(props.lang ?? 'de');
  const switchLanguage = (_lang) => {
    if (_lang === lang) return;
    setLang(_lang);
    props.onLanguageChange(_lang);
  };

  return (
    <div className="w-10">
      <div className="">
        {lang === 'en' && (
          <button type="button" className="w-10" onClick={() => switchLanguage('de')}>
            <img src={flag_de} className="w-10 rounded-full" alt="de" />
          </button>
        )}
        {lang === 'de' && (
          <button type="button" className="w-10  " onClick={() => switchLanguage('en')}>
            <img src={flag_en} className="w-10 rounded-full" alt="en" />
          </button>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitchComponent;
