/* eslint-disable @typescript-eslint/no-unused-vars */
import { Trans, useTranslation } from 'react-i18next';
import CheckBox from '../../common/checkbox/CheckBox';
import { useReducer, useState,useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Lock } from '../../../assets/images';
import './GdprComponent.css';
import ButtonV2 from '../../common/buttonv2/Buttonv2';

import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';
interface GdprProps {
  onAccept: (consent: any) => void;
  gdprView: boolean;
}

const GdprComponent: React.FunctionComponent<GdprProps> = (props) => {
  const privacyPageUrl = 'https://myfootprint.app/privacy-policy';
  const { onAccept, gdprView } = props;
  const [showGdpr, setShowGdpr] = useState(gdprView);
  const [cookies, setCookie] = useCookies();
  const [consent, setConsent] = useState({ technical: true, analytics: false });
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const { t } = useTranslation();

  const eventName = 'LP_FooterClicked';

  const confirmAndContinue = () => {
    console.log(`[gdpr] confirm and continue`);
    consentToCookie(consent);
    setShowGdpr(false);
  };

  const selectAllAndContinue = () => {
    
    setConsent({ technical: true, analytics: true });
    forceUpdate();
    consentToCookie({ technical: true, analytics: true });
    gdprAllAccepted();
    setShowGdpr(false);
  };

  const consentToCookie = (consent) => {
    if (consent.analytics) {
      setCookie('fpt-analytics', 'true');
    }

    if (consent.technical) {
      setCookie('fpt-technical', 'true');
    }
    console.log(`[gdpr] cookie set analytics:${consent.analytics}, technical: ${consent.technical}`);

    setTimeout(() => {
      onAccept(consent);
    }, 1000);
  };

  const gdprAllAccepted = () => {
    AnalyticsService.track("UM_GdprAllCookiesAccepted", {
      action: SA[SA.Clicked],
      channel: 'landingPage',
    },true);
  };
  const gdprAutoSkipped = () => {
    AnalyticsService.track("UM_GDPRAutoSkipped", {
      action: SA[SA.Skipped],
      channel: 'landingPage',
    },true);
  };
  const gdprTechConfirmationClicked = () => {
    AnalyticsService.track("UM_GdprTechConfirmationClicked", {
      action: SA[SA.Clicked],
      channel: 'landingPage',
    },true);
  };
  
  const gdprViewed = () => {
    AnalyticsService.track("UM_GDPRPageVisited", {
      action: SA[SA.Viewed],
      channel: 'landingPage',
    },true);
  };

  useEffect(() => { 
    if(showGdpr){
      gdprViewed();
    }else{
      gdprAutoSkipped();
    }
  }, []);

  return (
    <div className="flex left-0 absolute w-full justify-center lg:bottom-10  bottom-32 lg:pl-[8%] lg:pr-[8%]  md:pl-[8%] md:pr-[8%]  mx-auto">
      {showGdpr && (
        <div className="Gdpr-Container">
          <div className="Gdpr-Content">
            <Trans i18nKey="gdpr.txt1"></Trans>
            .
          </div>
          <ul className="Gdpr-Checkboxes">
            <li>
              <CheckBox text={t`gdpr.technical`} checked={true} clickable={false} />
            </li>
            <li>
              <CheckBox text={t`gdpr.analytics`} clickable={true} onToggle={gdprTechConfirmationClicked} />
            </li>
          </ul>
          <div className="Gdpr-Buttons gap-16 flex">
            <ButtonV2
              text={t`gdpr.select-selected`}
              size="large-button"
              enabled
              type="white-bg"
              onClick={confirmAndContinue}
            ></ButtonV2>
            <ButtonV2
              text={t`gdpr.select-all`}
              size="large-button"
              enabled
              type="full"
              onClick={selectAllAndContinue}
            ></ButtonV2>
          </div>
        </div>
      )}
    </div>
  );
};

export default GdprComponent;
