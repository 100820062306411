import { AnalyticsService } from '../../../services/AnalyticsService';

interface ClientCardPerfectFitProps {
  page: string;
  ctaValue: string;
  imageUrl?: string;
  externalLinkIcon: string;
  title: string;
  targetUrl: string;
  firstElement?: boolean;
}
const takeAction = async (pageName, ctaValue) => {
  AnalyticsService.track(pageName, {
    action: 'CTA',
    ctaId: ctaValue,
  });
  return null;
};


const openExternalUrl = async (targetUrl) => {
  window.open(targetUrl, '_blank');
  return null;
};


const handleLinkClick = (event, pageName, ctaValue, url) => {
  event.preventDefault();
  takeAction(pageName, ctaValue);
  openExternalUrl(url);
};

const HomePageClientCardPerfectFitComponent: React.FunctionComponent<ClientCardPerfectFitProps> = ({
  page,
  ctaValue,
  imageUrl,
  title,
  externalLinkIcon,
  targetUrl,
  firstElement,
}) => {
  return (
    <div className={`${firstElement === true ? 'mdSide:pl-24' : 'mdSide:pl-14'} ${firstElement === true ? 'md:pl-16' : 'md:pl-0'}`}>
      <a
       style={{cursor:'pointer'}} onClick={(event) => handleLinkClick(event, page, ctaValue, targetUrl)}
      >
        <div className={`w-[18.5rem]  min-w-fit relative sm:pl-8 md:pr-12  lg:pr-12`}>
          <img className="w-[18.5rem]" src={imageUrl} alt={title} />
          <div className="absolute top-1/2 left-1/2 md:pr-12  sm:pl-8  lg:pr-12  mdSide:pl-12 transform -translate-x-1/2 -translate-y-16 text-almond text-center font-bold text-[3.125rem]">
            {title}
          </div>
          {/* 
          <img
            src={externalLinkIcon}
            alt="external link"
            className="absolute right-10 top-10 transform md:-translate-x-6  translate-x-5 lg:-translate-x-8 -translate-y-2 text-white text-center font-bold w-[1.76rem]"
          /> */}
        </div>
      </a>
    </div>
  );
};

export default HomePageClientCardPerfectFitComponent;
