/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './UserTestingPage.css';
import { handsIcon, handsIcon_sm, rocketIcon, rocketIcon_sm,  yeti_test_product } from '../../../assets/images';
import { Trans, useTranslation } from 'react-i18next';
import AppContextService from '../../../context/AppContextService';
import { initialState } from '../../../context/AppState';
import FooterComponent from '../../common/footer/FooterComponent';
import MenuComponent from '../../common/menu/Menu';
import { useNavigate } from 'react-router-dom';
import PageHolderComponent from '../home/PageHolder';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';

import TopPageSwitcherComponent from '../home/TopPageSwitcher';
import ButtonV2 from '../../common/buttonv2/Buttonv2';

interface UserTestingProps {
  onLanguageChange(lang: string): void;
}

const UserTestingPage: React.FunctionComponent<UserTestingProps> = (props) => {
  // sm:h-72 md:h-96 md:mt-[-100px] md:w-3/5 md:absolute md:right-0
  //
  const contextService = AppContextService.getInstance(initialState);
  const gdpr = contextService.get('gdpr');
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSmallLG, setIsSmallLG] = useState(false);
  const gdprExists = gdpr !== null;
  const [showGdpr, setShowGdpr] = useState(!gdprExists);
  const lang = contextService.get('lang');
  const navigate = useNavigate();
  const pageName = 'Shape FPT Page';
  const eventName = 'LP_ShapeFPTPageVisited';
  const { t } = useTranslation();

  function checkIsSmallLG() {
    setIsSmallLG(window.innerWidth < 1440);
    return window.innerWidth < 1440;
  }
  function checkIsMobileView() {
    setIsMobileView(window.innerWidth < 1025); // Adjust the threshold value as needed
  }
  const dummyAction = async () => {
    return null;
  };
  
  useEffect(() => {
    document.title = t`LP.pageTitle.UserTests`;
  });
  
  useEffect(() => {

    AnalyticsService.track(eventName, {
      action: SA[SA.Viewed], 
      page: pageName,
    });
    const handleResize = () => {
      checkIsSmallLG();
      checkIsMobileView();
    };
    checkIsSmallLG();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  return (
    <div>
      <TopPageSwitcherComponent lang={lang}></TopPageSwitcherComponent>
      <PageHolderComponent>
        <a id="top"></a>
        <MenuComponent
          parentPage_linkId="create"
          parentPage={pageName}
          onLanguageChange={props.onLanguageChange}
          forceDarkColorLogo={true}
          useBlueBerryLogo={false}
          language={lang}
          showNotifyBox={false}
        ></MenuComponent>

        <div className="flex flex-col relative sm:text-center sm:justify-center sm:items-center ">
          <div className={`sm:hidden absolute top-0 right-0 mr-[-10%]  z-0   ${isSmallLG ? 'lg:w-[63rem] ' : 'lg:w-[56rem] mt-4'}`}>
            <img src={yeti_test_product} alt="Get the correct shoe size for your chosen shoe with Footprint" />
          </div>
          <div className="relative z-10 w-[100%] mt-24">
            <div className={`ut-big-title   ${isSmallLG ? 'lg:w-full text-3xl' : 'lg:w-2/3 text-[2.188rem]'}`}>
              <Trans i18nKey="LP.usertesting.title"></Trans>
            </div>
            <div className={`ut-sub-title mt-6  ${isSmallLG ? 'lg:w-full' : 'lg:w-2/3'}`}>
              <Trans i18nKey="LP.usertesting.title-info"></Trans>
            </div>

            <div className="mt-12">
              <a href="https://calendly.com/thefootprintapp/remote-user-testing" target="_blank" rel="noreferrer">
                <ButtonV2 text={t`LP.usertesting.button-text`} size="large-button" enabled type="full" onClick={dummyAction}></ButtonV2>
              </a>
              <p className="ut-sub-note ">
                <Trans i18nKey="LP.usertesting.button-warning"></Trans>
              </p>
            </div>
          </div>
          <div className="flex sm:flex-col sm:w-full z-10 mt-24 mb-36 sm:mt-16 sm:mb-16 w-2/3 md:w-full md:mt-80 md:mb-12">
            <div className="w-2/3 sm:w-full md:w-full">
              <div className="w-[100%] hidden lg:block">
                <img src={rocketIcon} alt="rocket" />
              </div>
              <div className="w-full flex sm:justify-center lg:hidden">
                <img src={rocketIcon_sm} className="sm:w-[2.188rem]" />
              </div>

              <h1 className={`ut-sub-title2 text-lg md:w-full  ${isSmallLG ? 'lg:text-lg  w-full' : ' lg: text-3xl w-2/3'}`}>
                <Trans i18nKey="LP.usertesting.subtitle-1"></Trans>
              </h1>
              <p className="ut-sub-note2 lg:w-2/3 md:w-4/5">
                <Trans i18nKey="LP.usertesting.subtitle-1-info"></Trans>
              </p>
            </div>
            <div className="w-2/3  sm:w-full sm:mt-12 md:w-full">
              <div className="w-[100%]  hidden lg:block ">
                <img src={handsIcon} alt="hand" />
              </div>
              <div className="w-[100%] flex sm:justify-center  lg:hidden ">
                <img src={handsIcon_sm} className="sm:w-[2.188rem]"  alt="hand"/>
              </div>
              <h1 className={`ut-sub-title2 text-lg  ${isSmallLG ? 'lg:text-lg w-full' : ' lg: text-3xl w-2/3'}`}>
                <Trans i18nKey="LP.usertesting.subtitle-2"></Trans>
              </h1>
              <p className="ut-sub-note2 lg:w-2/3 md:w-4/5">
                <Trans i18nKey="LP.usertesting.subtitle-2-info"></Trans>
              </p>
            </div>
          </div>
        </div>
        <div className="flex left-0 absolute w-full mt-12 items-start justify-start bg-[#1b1c1e]">
          <div className="flex w-full mdSide:py-12 lg:py-12  home-page-place-holder-margin ">
            <FooterComponent  parentPage={pageName} parentPage_linkId="create"></FooterComponent>
          </div>
        </div>
      </PageHolderComponent>
    </div>
  );
};

export default UserTestingPage;
