import { useState, useCallback } from 'react';
import { Checked, UnChecked, DisabledChecked } from '../../../assets/images';

interface CheckBoxProps {
  text: string;
  checked?: boolean;
  clickable?: boolean;
  textClass?: string;
  onToggle?(): void;
}

const CheckBox: React.FunctionComponent<CheckBoxProps> = ({ text, checked = false, clickable = true, textClass, onToggle }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const checkImage = useCallback(() => (clickable ? (isChecked ? Checked : UnChecked) : DisabledChecked), [isChecked]);

  const toggleChecked = () => {
    if (!clickable) return;
    setIsChecked(!isChecked);
    onToggle?.();
  };

  const getTextClass = () =>
    textClass ?? 'pt-0.5 lg:text-sm text-[0.75rem] select-none justify-center items-center text-darkslateblue flex';

  return (
    <div onClick={() => toggleChecked()} className="cursor-pointer flex mb-3.5">
      <div className="w-[1.875rem] mr-3">
        <img src={checkImage()} alt="check" />
      </div>
      <div className={getTextClass()}>{text}</div>
    </div>
  );
};

export default CheckBox;
