import { AuthResult } from "../models/AuthResult";
import { User } from "../models/User";

export interface Gdpr {
  technical: boolean;
  analytics: boolean;
}

export interface IState {
  gdpr: Gdpr | null,
  lang: string | null;
  token: AuthResult | null;
  user: User | null;
  userEmail: string;
}

export const initialState: IState = {
  gdpr: null,
  lang: null,
  token: null,
  user: null,
  userEmail: '',
};
