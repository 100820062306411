import { sb_fp_de, sb_fp_en, sb_fs_highlight_de, sb_fs_highlight_en } from '../../../assets/images';
import { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface TopPageSwitcherProps {
  show?: boolean;
  lang: string;
}

const TopPageSwitcherComponent: React.FunctionComponent<TopPageSwitcherProps> = ({ lang, show = true }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isInView, setIsInView] = useState(true);
  const [slideDistance, setSlideDistance] = useState(3);

  const controls = useAnimation();

  const updateSlideDistance = (width: number) => {
    if (width >= 2560) {
      setSlideDistance(80);
    } else if (width >= 1920) {
      setSlideDistance(50);
    } else if (width >= 1440) {
      setSlideDistance(50);
    } else if (width >= 1024) {
      setSlideDistance(50);
    } else {
      setSlideDistance(50);
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsInView(currentScrollY <= 50);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      updateSlideDistance(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    updateSlideDistance(screenWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    controls.start({
      y: isInView ? `-${slideDistance}px` : `-500px`,
      transition: { duration: 0.3, ease: 'easeInOut' },
    });
  }, [isInView, slideDistance]);

  return (
    <>
      {show && (
        <motion.div
          className="top-8  fixed flex w-full items-center justify-center z-50"
          animate={controls}
          style={{ height: slideDistance }}
        >
          <div className="topSwitch">
            <div className="flex  items-center justify-center top-0">
              <div>
                <div className="absolute left-6 top-16">
                  <img src={lang === 'en' ? sb_fs_highlight_en : sb_fs_highlight_de} className="w-[11.063rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
                </div>
                <div className="absolute right-14 top-[4.5rem]">
                  <a href="https://partners.footprinttech.de/">
                    <img src={lang === 'en' ? sb_fp_en : sb_fp_de} className="w-[5rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default TopPageSwitcherComponent;
