import { useEffect, useRef } from 'react';

interface ClientCardProps {
  children: React.ReactNode;
}

const SliderComponent: React.FunctionComponent<ClientCardProps> = ({ children }) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  let mouseDown = false;
  let startX, scrollLeft;

  let startDragging = function (e) {
    if (sliderRef.current) {
      mouseDown = true;
      startX = e.pageX - sliderRef.current.offsetLeft;
      scrollLeft = sliderRef.current.scrollLeft;
    }
  };

  let stopDragging = function (event) {
    mouseDown = false;
    if (sliderRef.current) {
    }
  };

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }
    const sliderMouseMove = (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - sliderRef.current!.offsetLeft;
      let scroll = x - startX;
      sliderRef.current!.scrollLeft = scrollLeft - scroll;
    };
    sliderRef.current.addEventListener('mousemove', sliderMouseMove);

    // Add the event listeners
    sliderRef.current.addEventListener('mousedown', startDragging, false);
    sliderRef.current.addEventListener('mouseup', stopDragging, false);
    sliderRef.current.addEventListener('mouseleave', stopDragging, false);

    return () => {
      if (!sliderRef.current) {
        return;
      }
      sliderRef.current!.removeEventListener('mousemove', sliderMouseMove);
      sliderRef.current!.removeEventListener('mousedown', startDragging);
      sliderRef.current!.removeEventListener('mouseup', stopDragging);
      sliderRef.current!.removeEventListener('mouseleave', stopDragging);
    };
  }, [sliderRef]);

  return (
    <div className="flex gap-1 pb-2 w-full overflow-x-scroll overflow-y-hidden slider snap-mandatory snap-x cursor-grab sm:pr-12 md:pr-48 md:ml-[5%]  mdSide:pr-48  mdSide:ml-[5%]">
      {children}
    </div>
  );
};

export default SliderComponent;
