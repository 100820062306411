import React, { useEffect, useState } from 'react';
import './PrivacyPolicy.css';
import AppContextService from '../../../context/AppContextService';
import { initialState } from '../../../context/AppState';
import FooterComponent from '../../common/footer/FooterComponent';
import MenuComponent from '../../common/menu/Menu';
import TopPageSwitcherComponent from '../home/TopPageSwitcher';
import { motion } from 'framer-motion';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';
import PageHolderComponent from '../home/PageHolder';
import { back_arrow } from '../../../assets/images';
interface PrivacyPolicyProps {
  onLanguageChange(lang: string): void;
}

const PrivacyPolicy: React.FunctionComponent<PrivacyPolicyProps> = (props) => {
  const contextService = AppContextService.getInstance(initialState);
  const gdpr = contextService.get('gdpr');
  const [isSmallLG, setIsSmallLG] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const gdprExists = gdpr !== null;
  const lang = contextService.get('lang');
  const pageName = 'Imprint';

  function checkIsMobileView() {
    setIsMobileView(window.innerWidth < 1025); // Adjust the threshold value as needed
  }

  function checkIsSmallLG() {
    setIsSmallLG(window.innerWidth < 1440);
  }
  useEffect(() => {
    console.log(isSmallLG);
    AnalyticsService.track(pageName, {
      action: SA[SA.Viewed],
      language: lang,
    });
    const handleResize = () => {
      checkIsSmallLG();
      checkIsMobileView();
    };

    checkIsSmallLG();
    checkIsMobileView();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <motion.div className="overflow-x-hidden">
      <TopPageSwitcherComponent lang={lang}></TopPageSwitcherComponent>
      <PageHolderComponent>
        <a id="top"></a>
        <MenuComponent
          parentPage_linkId="privacy"
          parentPage={pageName}
          onLanguageChange={props.onLanguageChange}
          forceDarkColorLogo={true}
          useBlueBerryLogo={false}
          language={lang}
          showNotifyBox={false}
        ></MenuComponent>
        <div className="flex flex-col">
          <div className="flex mt-12">
            <a href="/" className="flex" rel="noreferrer">
              <img src={back_arrow} />
              <span className="Back-to-Home">Back to Home</span>
            </a>
          </div>
          <div className="pp-title mt-12">Privacy Policy</div>
          <div className="pp-title-date mt-6">Date: 17 November 2022</div>
          <div className="pp-title-date">Version number: 1.0</div>
          <div className="pp-body mt-16">
            <div className="pp-text-block-title">Datenschutz­erklärung</div>
            <div className="pp-text-block-title">1. Datenschutz auf einen Blick</div>
            <div className="pp-text-block-title">Allgemeine Hinweise</div>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
            Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
            Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
            <div className="pp-text-block-title">Datenerfassung auf dieser Website</div>
            <div className="pp-text-block-title">Wer ist verantwortlich für die Datenerfassung auf dieser Website?</div>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
            <div className="pp-text-block-title">Wie erfassen wir Ihre Daten?</div>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie
            in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
            unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
            <div className="pp-text-block-title">Wofür nutzen wir Ihre Daten?</div>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur
            Analyse Ihres Nutzerverhaltens verwendet werden.
            <div className="pp-text-block-title">Welche Rechte haben Sie bezüglich Ihrer Daten?</div>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen
            Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine
            Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
            haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema
            Datenschutz können Sie sich jederzeit an uns wenden.
            <div className="pp-text-block-title">Analyse-Tools und Tools von Dritt­anbietern</div>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen. Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
            <div className="pp-text-block-title">2. Hosting und Content Delivery Networks (CDN)</div>
            <div className="pp-text-block-title">Externes Hosting</div>
            Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website
            erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
            Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website
            generiert werden, handeln. Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
            bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung
            unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Unser Hoster wird Ihre Daten nur
            insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese
            Daten befolgen. Wir setzen folgenden Hoster ein: ALL-INKL.COM – Neue Medien Münnich Inhaber: René Münnich Hauptstraße 68 D-02742
            Friedersdorf
            <div className="pp-text-block-title">Abschluss eines Vertrages über Auftragsverarbeitung</div>
            Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster
            geschlossen.
            <div className="pp-text-block-title">3. Allgemeine Hinweise und Pflicht­informationen</div>
            <div className="pp-text-block-title">Datenschutz</div>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website
            benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
            Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
            Dritte ist nicht möglich.
            <div className="pp-text-block-title">Hinweis zur verantwortlichen Stelle</div>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: Footprint Technologies GmbH Alt-Stralau 5b
            10245 Berlin Vertretungsberechtigte Geschäftsführer: Dr. Matthias Brendel, Muhammad Kezze, Carolin Kleinert Phone: +49 30
            81457917 E-mail: info@footprinttech.de Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
            gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o.
            Ä.) entscheidet.
            <div className="pp-text-block-title">Speicherdauer</div>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
            Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder
            eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen
            Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
            letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
            <div className="pp-text-block-title">Gesetzlich vorgeschriebener Datenschutz­beauftragter</div>
            Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt. Footprint Technologies GmbH Hr. Dr. Matthias Brendel
            Alt-Stralau 5b 10245 Berlin Telefon: +49 30 81457917 E-mail: info@footprinttech.de
            <div className="pp-text-block-title">Hinweis zur Datenweitergabe in die USA</div>
            Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind,
            können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin,
            dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen
            könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten
            zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen
            Einfluss.
            <div className="pp-text-block-title">Widerruf Ihrer Einwilligung zur Datenverarbeitung</div>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte
            Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
            unberührt.
            <div className="pp-text-block-title">
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
            </div>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
            GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
            EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
            VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
            PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
            VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
            DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
            DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM
            ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            <div className="pp-text-block-title">Beschwerde­recht bei der zuständigen Aufsichts­behörde</div>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
            Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            <div className="pp-text-block-title">Recht auf Daten­übertrag­barkeit</div>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
            an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
            Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            <div className="pp-text-block-title">SSL- bzw. TLS-Verschlüsselung</div>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
            Dritten mitgelesen werden.
            <div className="pp-text-block-title">Auskunft, Löschung und Berichtigung</div>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit an uns wenden.
            <div className="pp-text-block-title">Recht auf Einschränkung der Verarbeitung</div>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen: Wenn Sie die Richtigkeit
            Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die
            Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn die
            Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der
            Datenverarbeitung verlangen. Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
            Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
            muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Wenn Sie die
            Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
            Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
            anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
            oder eines Mitgliedstaats verarbeitet werden.
            <div className="pp-text-block-title">Widerspruch gegen Werbe-E-Mails</div>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
            <div className="pp-text-block-title">4. Datenerfassung auf dieser Website</div>
            <div className="pp-text-block-title">Cookies</div>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen
            Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies)
            auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben
            auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
            Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten
            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
            Cookies zur Abwicklung von Zahlungsdienstleistungen). Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
            notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von
            Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen. Cookies, die zur Durchführung des
            elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
            (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur Messung des
            Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch
            fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt
            wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO); die Einwilligung ist jederzeit widerrufbar. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
            informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen
            sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
            Funktionalität dieser Website eingeschränkt sein. Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden,
            werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.
            <div className="pp-text-block-title">Cookie-Einwilligung mit Borlabs Cookie</div>
            Unsere Website nutzt die Cookie-Consent-Technologie von Borlabs Cookie, um Ihre Einwilligung zur Speicherung bestimmter Cookies
            in Ihrem Browser einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist Borlabs – Benjamin A.
            Bornschein, Georg-Wilhelm-Str. 17, 21107 Hamburg (im Folgenden Borlabs). Wenn Sie unsere Website betreten, wird ein
            Borlabs-Cookie in Ihrem Browser gespeichert, in dem die von Ihnen erteilten Einwilligungen oder der Widerruf dieser
            Einwilligungen gespeichert werden. Diese Daten werden nicht an den Anbieter von Borlabs Cookie weitergegeben. Die erfassten
            Daten werden gespeichert, bis Sie uns zur Löschung auffordern bzw. das Borlabs-Cookie selbst löschen oder der Zweck für die
            Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungsfristen bleiben unberührt. Details zur Datenverarbeitung von
            Borlabs Cookie finden Sie unter
            <div className="pp-text-block-link">
              <a href="https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie" target="_blank" rel="noreferrer">
                https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie
              </a>
              /
            </div>
            Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz
            von Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. c DSGVO.
            <div className="pp-text-block-title">Server-Log-Dateien</div>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind: - Browsertyp und Browserversion - verwendetes Betriebssystem - Referrer URL -
            Hostname des zugreifenden Rechners - Uhrzeit der Serveranfrage - IP-Adresse Eine Zusammenführung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
            hierzu müssen die Server-Log-Files erfasst werden.
            <div className="pp-text-block-title">Kontaktformular</div>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen
            dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese
            Daten geben wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
            b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) sofern diese abgefragt wurde. Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
            Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
            abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
            unberührt.
            <div className="pp-text-block-title">Anfrage per E-Mail, Telefon oder Telefax</div>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden
            personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese
            Daten geben wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
            b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) sofern diese abgefragt wurde. Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
            Aufbewahrungsfristen – bleiben unberührt.
            <div className="pp-text-block-title">5. Soziale Medien</div>
            <div className="pp-text-block-title">LinkedIn Plugin</div>
            Diese Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
            Place, Dublin 2, Irland. Bei jedem Abruf einer Seite dieser Website, die Funktionen von LinkedIn enthält, wird eine Verbindung
            zu Servern von LinkedIn aufgebaut. LinkedIn wird darüber informiert, dass Sie diese Website mit Ihrer IP-Adresse besucht haben.
            Wenn Sie den „Recommend-Button“ von LinkedIn anklicken und in Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn
            möglich, Ihren Besuch auf dieser Website Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter
            der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn haben. Die Verwendung des
            LinkedIn-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde,
            erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
            widerrufbar. Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:
            <div className="pp-text-block-link">
              <a
                href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de"
                target="_blank"
                rel="noreferrer"
              >
                https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de
              </a>
            </div>
            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter:
            <div className="pp-text-block-link">
              <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer">
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </div>
            <div className="pp-text-block-title">6. Analyse-Tools und Werbung</div>
            <div className="pp-text-block-title">Google Analytics</div>
            Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“),
            Gordon House, Barrow Street, Dublin 4, Irland. Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
            Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe,
            Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem Profil
            zusammengefasst, das dem jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist. Google Analytics verwendet Technologien, die die
            Wiedererkennung des Nutzers zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.B. Cookies oder Device-Fingerprinting).
            Die von Google erfassten Informationen über die Benutzung dieser Website werden in der Regel an einen Server von Google in den
            USA übertragen und dort gespeichert. Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
            Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
            Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
            widerrufbar. Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:
            <div className="pp-text-block-link">
              <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank" rel="noreferrer">
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </div>
            <div className="pp-text-block-title">IP Anonymisierung</div>
            Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor
            der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
            Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            <div className="pp-text-block-title">Browser Plugin</div>
            Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:{' '}
            <span className="pp-text-block-link">
              <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </span>
            . Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:
            <span className="pp-text-block-link">
              <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noreferrer">
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
            </span>
            .<div className="pp-text-block-title">Auftragsverarbeitung</div>
            Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
            <div className="pp-text-block-title">Demografische Merkmale bei Google Analytics</div>
            Diese Website nutzt die Funktion „demografische Merkmale“ von Google Analytics, um den Websitebesuchern passende Werbeanzeigen
            innerhalb des Google-Werbenetzwerks anzeigen zu können. Dadurch können Berichte erstellt werden, die Aussagen zu Alter,
            Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus
            Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion
            jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
            Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.
            <div className="pp-text-block-title">Google Analytics E-Commerce-Tracking</div>
            Diese Website nutzt die Funktion „E-Commerce-Tracking“ von Google Analytics. Mit Hilfe von E-Commerce-Tracking kann der
            Websitebetreiber das Kaufverhalten der Websitebesucher zur Verbesserung seiner Online-Marketing-Kampagnen analysieren. Hierbei
            werden Informationen, wie zum Beispiel die getätigten Bestellungen, durchschnittliche Bestellwerte, Versandkosten und die Zeit
            von der Ansicht bis zum Kauf eines Produktes erfasst. Diese Daten können von Google unter einer Transaktions-ID zusammengefasst
            werden, die dem jeweiligen Nutzer bzw. dessen Gerät zugeordnet ist.
            <div className="pp-text-block-title">Speicherdauer</div>
            Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z.
            B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu
            ersehen Sie unter folgendem Link:
            <div className="pp-text-block-link">
              <a href="https://support.google.com/analytics/answer/7667196?hl=de" target="_blank" rel="noreferrer">
                https://support.google.com/analytics/answer/7667196?hl=de
              </a>
            </div>
            <div className="pp-text-block-title">WP Statistics</div>
            Diese Website nutzt das Analysetool WP Statistics, um Besucherzugriffe statistisch auszuwerten. Anbieter ist Veronalabs, ARENCO
            Tower, 27th Floor, Dubai Media City, Dubai, Dubai 23816, UAE (
            <a href="https://veronalabs.com" target="_blank" className="pp-text-block-link" rel="noreferrer">
              https://veronalabs.com
            </a>
            ). Mit WP Statistics können wir die Nutzung unserer Website analysieren. WP Statistics erfasst dabei u. a. Logdateien
            (IP-Adresse, Referrer, verwendete Browser, Herkunft des Nutzers, verwendete Suchmaschine) und Aktionen, die die Websitebesucher
            auf der Seite getätigt haben (z. B. Klicks und Ansichten). Die mit WP Statistics erfassten Daten werden ausschließlich auf
            unserem eigenen Server gespeichert und nicht an WordPress weitergegeben. Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage
            von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um
            sowohl unser Webangebot als auch unsere Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B.
            eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO; die Einwilligung ist jederzeit widerrufbar.
            <div className="pp-text-block-title">7. Newsletter</div>
            <div className="pp-text-block-title">Newsletter­daten</div>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
            Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten
            verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter. Die
            Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
            Versand des Newsletters können Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der
            bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des Newsletter-Bezugs bei
            uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
            gespeichert und nach der Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir
            behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten
            Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren. Nach Ihrer Austragung aus der Newsletterverteilerliste
            wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um künftige Mailings zu
            verhindern. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies
            dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
            (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.
            <div className="pp-text-block-title">
              Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.
            </div>
            <div className="pp-text-block-title">8. Plugins und Tools Google reCAPTCHA</div>
            Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google Ireland Limited („Google“),
            Gordon House, Barrow Street, Dublin 4, Irland. Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z.
            B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA
            das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher
            die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des
            Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google
            weitergeleitet. Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass
            eine Analyse stattfindet. Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
            Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor
            SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage
            von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar. Weitere Informationen zu Google reCAPTCHA entnehmen
            Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links:
            <div className="pp-text-block-link">
              <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer">
                https://policies.google.com/privacy?hl=de
              </a>
            </div>
            und
            <div className="pp-text-block-link">
              <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noreferrer">
                https://policies.google.com/terms?hl=de.
              </a>
            </div>
            <div className="pp-text-block-title">9. Audio- und Videokonferenzen</div>
            <div className="pp-text-block-title">Datenverarbeitung</div>
            Für die Kommunikation mit unseren Kunden setzen wir unter anderen Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten
            Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder Audiokonferenz via Internet kommunizieren, werden Ihre
            personenbezogenen Daten von uns und dem Anbieter des jeweiligen Konferenz-Tools erfasst und verarbeitet. Die Konferenz-Tools
            erfassen dabei alle Daten, die Sie zur Nutzung der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre Telefonnummer).
            Ferner verarbeiten die Konferenz-Tools die Dauer der Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz, Anzahl
            der Teilnehmer und sonstige „Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang (Metadaten). Des Weiteren
            verarbeitet der Anbieter des Tools alle technischen Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind. Dies
            umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version, Client-Version,
            Kameratyp, Mikrofon oder Lautsprecher sowie die Art der Verbindung. Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen
            oder in sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu solchen
            Inhalten zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und Videos, Dateien,
            Whiteboards und andere Informationen, die während der Nutzung des Dienstes geteilt werden. Bitte beachten Sie, dass wir nicht
            vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere Möglichkeiten richten sich
            maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung durch die
            Konferenztools entnehmen Sie den Datenschutzerklärungen der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt
            haben.
            <div className="pp-text-block-title">Zweck und Rechtsgrundlagen</div>
            Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden Vertragspartnern zu kommunizieren oder bestimmte
            Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 S. 1 lit. b DSGVO). Des Weiteren dient der Einsatz der Tools der
            allgemeinen Vereinfachung und Beschleunigung der Kommunikation mit uns bzw. unserem Unternehmen (berechtigtes Interesse im Sinne
            von Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf
            Grundlage dieser Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die Zukunft widerrufbar.
            <div className="pp-text-block-title">Speicherdauer</div>
            Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt.
            Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
            unberührt. Auf die Speicherdauer Ihrer Daten, die von den Betreibern der Konferenz-Tools zu eigenen Zwecken gespeichert werden,
            haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der Konferenz-Tools.
            <div className="pp-text-block-title">Eingesetzte Konferenz-Tools</div>
            Wir setzen folgende Konferenz-Tools ein:
            <div className="pp-text-block-title">Skype for Business</div>
            Wir nutzen Skype for Business. Anbieter ist Skype Communications SARL, 23-29 Rives de Clausen, L-2165 Luxembourg. Details zur
            Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Skype:
            <div className="pp-text-block-link">
              <a href="https://privacy.microsoft.com/de-de/privacystatement/" target="_blank" rel="noreferrer">
                https://privacy.microsoft.com/de-de/privacystatement/
              </a>
            </div>
            .<div className="pp-text-block-title">Abschluss eines Vertrags über Auftragsverarbeitung</div>
            Wir haben mit dem Anbieter von Skype for Business einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen
            Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Skype for Business vollständig um.
            <div className="pp-text-block-title">Microsoft Teams</div>
            Wir nutzen Microsoft Teams. Anbieter ist die Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA. Details zur
            Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Microsoft Teams:
            <div className="pp-text-block-link">
              <a href="https://privacy.microsoft.com/de-de/privacystatement" target="_blank" rel="noreferrer">
                https://privacy.microsoft.com/de-de/privacystatement
              </a>
            </div>
            .<div className="pp-text-block-title">Abschluss eines Vertrags über Auftragsverarbeitung</div>
            Wir haben mit dem Anbieter von Microsoft Teams einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen
            Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Microsoft Teams vollständig um.
            <div className="pp-text-block-title">10. Eigene Dienste</div>
            <div className="pp-text-block-title">OneDrive</div>
            Wir haben OneDrive auf dieser Website eingebunden. Anbieter ist die Microsoft Corporation, One Microsoft Way, Redmond, WA
            98052-6399, USA (im folgenden „OneDrive“). OneDrive ermöglicht es uns, einen Uploadbereich auf unserer Website einzubinden, in
            dem Sie Inhalte hochladen können. Wenn Sie Inhalte hochladen, werden diese auf den Servern von OneDrive gespeichert. Wenn Sie
            unsere Website betreten wird außerdem eine Verbindung zu OneDrive aufgebaut, sodass OneDrive feststellen kann, dass Sie unsere
            Website besucht haben. Die Verwendung von OneDrive erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
            hat ein berechtigtes Interesse an einem zuverlässigen Uploadbereich auf seiner Website. Sofern eine entsprechende Einwilligung
            abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
            jederzeit widerrufbar.
            <div className="pp-text-block">Abschluss eines Vertrags über Auftragsverarbeitung</div>
            Wir haben einen Vertrag über Auftragsverarbeitung mit OneDrive geschlossen. Hierbei handelt es sich um einen
            datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass OneDrive die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </div>
        </div>
        <div className="flex left-0 absolute w-full mt-12 items-start justify-start bg-[#1b1c1e]">
          <div className="flex w-full mdSide:py-12 lg:py-12  home-page-place-holder-margin ">
            <FooterComponent  parentPage={pageName} parentPage_linkId="privacy"></FooterComponent>
          </div>
        </div>
      </PageHolderComponent>
    </motion.div>
  );
};

export default PrivacyPolicy;
