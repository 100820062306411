import { ReactNode } from 'react';

interface PageHolderProps {
  children: ReactNode;
}

const PageHolderComponent: React.FunctionComponent<PageHolderProps> = ({ children }) => {
  return <div className="pt-16 home-page-place-holder-margin">{children}</div>;
};

export default PageHolderComponent;
