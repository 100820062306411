interface ExploreAppSectionTitleProps {
  icon: string;
  title: string;
}

const ExploreAppSectionTitleComponent: React.FunctionComponent<ExploreAppSectionTitleProps> = ({ icon, title }) => {
  return (
    <>
      <div className="lg:flex mdSide:flex hidden  items-center gap-6 mb-12 align-middle">
        <img src={icon} className="lg:w-[4.875rem] mdSide:w-[3.625rem] " alt="Get the correct shoe size for your chosen shoe with Footprint" />
        <b className="home-page-section-title-bold self-center mdSide:text-[1.5rem] lg:text-[2.125rem] text-darkslateblue bg-almond font-title tracking-wide whitespace-nowrap">
          {title}
        </b>
      </div>
      <div className="lg:hidden mdSide:hidden flex items-center gap-6 mb-4 align-middle">
        <b className="home-page-section-title-bold self-center text-[1.5rem] text-darkslateblue bg-almond font-title tracking-wide whitespace-nowrap">
          {title}
        </b>
        <img src={icon} className="w-[2.188rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
      </div>
    </>
  );
};

export default ExploreAppSectionTitleComponent;
