import React, { useEffect } from 'react';
import { initialState, IState } from './AppState';
import AppContextService from './AppContextService';

export const AppContext = React.createContext<IState>(initialState);

export const AppContextProvider = (props: any) => {
  //const [checkedUser, setCheckedUser] = useState(false);
  //const isEmailVerify = window.location.toString().indexOf('EmailVerified') > 0;
  //const isPasswordReset = window.location.toString().indexOf('PasswordReset') > 0;

  const contextService = AppContextService.getInstance(initialState);
  const state = contextService.getState();
  
  useEffect(() => {
    const lang = contextService.get('lang');
    if (lang) {
      console.log('language', lang);
    }
  });

  return (    
      <AppContext.Provider value={state}>
        {props.children}        
      </AppContext.Provider>
  );
};
