interface SizeGuideProps {
  show: boolean;
}

const SizeGuideComponent: React.FunctionComponent<SizeGuideProps> = (props) => {
  if (!props.show) {
    return <></>;
  }

  return (
    <div className="absolute top-0 left-0 w-12 text-center text-3xl z-50">
      <div className="w-12 absolute bg-white sm:block text-red-500">SM</div>
      <div className="w-12 absolute hidden bg-white md:block text-green-500">MD</div>
      <div className="w-20 absolute hidden bg-white mdSide:block text-blue-500">MD-S</div>
      <div className="w-12 absolute hidden bg-white lg:block text-orange-500">LG</div>
      {/* <div className="w-12 absolute hidden bg-white xl:block text-blue-500">XL</div> */}
      <div className="w-12 absolute hidden bg-white landscape:block text-sm mt-12">landscape</div>
      <div className="w-12 absolute hidden bg-white portrait:block text-sm mt-12">portrait</div>
    </div>
  );
};

export default SizeGuideComponent;
