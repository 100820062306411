import React, { useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { initialState } from '../../../context/AppState';
import AppContextService from '../../../context/AppContextService';
import axios from 'axios';

interface DemoProps {
  onLanguageChange(lang: string): void;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {},
});

const Demo: React.FunctionComponent<DemoProps> = (props) => {
  const contextService = AppContextService.getInstance(initialState);
  const lang = contextService.get('lang');
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t`pageTitle.Demo`;
    client.post('sessions/demo', {
      article_number: 'WMS_TABLE_FULL'
    }, {
      auth: {
        username: 'podolino',
        password: 'fpt-test'
      }
    }).then(response => {
      console.log('got demo response', response.headers);
      window.location.replace(response.headers.location);
    });

  },[]);

  return (<></>)

};

export default Demo;
