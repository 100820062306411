/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import './HomePage.css';
import {
  pillHero,
  ricosta,
  hand_mobile,
  icon_footwear,
  icon_profiles,
  icon_co2,
  icon_cloud_globe,
  icon_footwear_light,
  icon_profiles_light,
  icon_co2_light,
  hand,
  anchor,
  piggy_bank,
  icon_shipping,
  externalLinkIcon,
  waves,
  LogoDark,
  baabuk,
  baabuk_logo,
  baabuk_mobile,
  baabuk_perfectfit,
  ricosta_perfectfit,
  ricosta_mobile,
} from '../../../assets/images';
import { Trans, useTranslation } from 'react-i18next';
import GdprComponent from '../gdpr/GdprComponent';
import AppContextService from '../../../context/AppContextService';
import { initialState } from '../../../context/AppState';
import FooterComponent from '../../common/footer/FooterComponent';
import MenuComponent from '../../common/menu/Menu';
import { useNavigate } from 'react-router-dom';
import ClientCardComponent from './HomePageClientCard';
import WhiteCardComponent from './HomePageWhiteCard';
import ButtonV2 from '../../common/buttonv2/Buttonv2';
import HomePageBulletInfoComponent from './HomePageBulletInfo';
import HomePageSectionTitleComponent from './HomePageSectionTitle';
import HomePageSectionTitleAltComponent from './HomePageSectionTitleAlt';
import HomePageCarouselCardComponent from './HomePageCarouselCard';
import HomePageClientCardPerfectFitComponent from './HomePageClientCardPerfectFit';
import SliderComponent from './SliderComponent';
import TopPageSwitcherComponent from './TopPageSwitcher';
import { motion } from 'framer-motion';
import SquareCards from './HomeCarousel';
import PageHolderComponent from './PageHolder';
import HomePageVideoSwitchv2 from './HomePageVideoSwitchV2';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';
import HomePageVideoSwitchMobile from './HomePageVideoSwitchMobile';
import HomePageCoCalculatorComponent from './HomePageCoCalculator';
import BackendService from '../../../services/BackendService';

interface HomePageProps {
  onLanguageChange(lang: string): void;
}

const HomePage: React.FunctionComponent<HomePageProps> = (props) => {
  const contextService = AppContextService.getInstance(initialState);
  const gdpr = contextService.get('gdpr');
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSmallLG, setIsSmallLG] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const gdprExists = gdpr !== null;
  const [showGdpr, setShowGdpr] = useState(!gdprExists);
  const lang = contextService.get('lang');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageName = 'Homepage';
  const eventName ='LP_HomepageVisited';

  const timerRef = useRef(null);

  
  function checkIsSmallLG() {
    setIsSmallLG(window.innerWidth < 1440);
    return window.innerWidth < 1440;
  }
  const onAccept = (consent) => {
   
  };
  
  useEffect(() => {
    document.title = t`LP.pageTitle.Homepage`;
   

  },[]);

  useEffect(() => {
    setTimeout(() => {
      AnalyticsService.track(eventName, {
        page: pageName,
        action: SA[SA.Viewed], 
      });
    }, 1500);
 
    
    
    const handleResize = () => {
      checkIsSmallLG();
      setIsMobileView(window.innerWidth < 1025);
    };
    checkIsSmallLG();
    const handleFontsLoaded = () => {
      handleResize();
      requestAnimationFrame(() => {
        setTimeout(() => {
          setIsReady(true);
        }, 1000);
      });
    };
    if (document.fonts && document.fonts.ready) {
      document.fonts.ready.then(handleFontsLoaded);
    } else {
      window.addEventListener('load', handleFontsLoaded);
      window.addEventListener('resize', handleResize);
      setIsMobileView(window.innerWidth < 1025);
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('load', handleResize);
      };
    }
    timerRef.current = Date.now();
  });

  const callPageClosed = () => {
    const timeSpent = (Date.now() - timerRef.current) / 1000;
    AnalyticsService.track(eventName, {
      page: pageName,
      action: SA[SA.Closed],
      duration: timeSpent, 
    });
  };

  window.onbeforeunload = () => {};

  const takeAction = async (ctaValue) => {
    AnalyticsService.track("LP_CTAButtonClicked", {
      action: 'CTA',
      ctaId: ctaValue,
    });
    return null;
  };

  const ExploreAppClicked = () => {
    window.scrollTo(0, 0);
    AnalyticsService.track("LP_CTAButtonClicked", {
      action: 'CTA',
      ctaId: 'exploreAppButton',
    });
    callPageClosed();
    navigate('/Explore-Our-App#top');
  };
  const ExploreStoresClicked = () => {
    Co2Clicked();
    navigate('/#findyourperfectfit');
    const element = document.getElementById('findyourperfectfit');
    if (element) {
      const yOffset = -20; // A negative value to add some space above the target element
      const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
    }
  };

  const Co2Clicked = () => {
    AnalyticsService.track("LP_CTAButtonClicked", {
      page: pageName, 
      buttonId :"co2CalculatorShopButton"
    });
  }
  return (
    <>
      {isReady ? (
        <motion.div className="overflow-x-hidden">
          <TopPageSwitcherComponent lang={lang}></TopPageSwitcherComponent>
          <PageHolderComponent>
            <MenuComponent
              parentPage_linkId="home_page"
              parentPage={pageName}
              onLanguageChange={props.onLanguageChange}
              forceDarkColorLogo={true}
              useBlueBerryLogo={false}
              language={lang}
              showNotifyBox={false}
            ></MenuComponent>
            <>
              <div className="hidden mdSide:flex lg:flex lg:pt-24 mdSide:pt-12 w-full items-center justify-start  bg-almond">
                <div className="w-full">
                  <div className="flex ">
                    <div className="w-1/2 flex flex-col gap-12 justify-between h-full">
                      <div className="hero-clamp">
                        <Trans i18nKey="LP.slogan.hero"></Trans>
                      </div>
                      <div className="text-darkslateblue text-xl font-[400] leading-6">
                        <Trans i18nKey="LP.info.hero"></Trans>
                      </div>
                      <a href="#Videos" className="p-0 mdSide:mt-8 mt-12">
                        <ButtonV2
                          text={t`LP.discover.more`}
                          size="large-button"
                          enabled
                          type="full"
                          onClick={() => {
                            takeAction('discoverMoreButton');
                          }}
                        ></ButtonV2>
                      </a>
                    </div>
                    <div className="flex items-end mdSide:items-start justify-end ml-auto">     
             
                      <img src={pillHero} className="w-[28.125rem] mdSide:w-[20.375rem]"  alt="Get the correct shoe size for your chosen shoe with Footprint" />
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-full">
                    <div className="flex justify-center items-center h-32 w-32 ">
                      <a href="#Videos">
                        <img src={anchor} alt="anchor" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* MOBILE HERO */}
              <div className="flex lg:hidden mdSide:hidden flex-col w-full items-center justify-center md:pt-24 sm:pt-12 ">
                <div className="flex w-full h-full ">
                  <div className="w-1/2 flex flex-col gap-5 justify-center md:mb-24">
                    <div className="hero-clamp">
                      <Trans i18nKey="LP.slogan.hero"></Trans>
                    </div>
                  </div>
                  <div className="w-1/2 flex">
                    <img src={pillHero} className="md:w-[21.25rem] " alt="Get the correct shoe size for your chosen shoe with Footprint" />
                  </div>
                </div>
                <div className="text-darkslateblue text-base md:text-xl md:w-2/3 sm:w-full sm:px-6 py-12 text-center">
                  <Trans i18nKey="LP.info.hero"></Trans>
                </div>
                <a href="#Videos">
                  <ButtonV2
                    text={t`LP.discover.more`}
                    size="large-button"
                    enabled
                    type="full"
                    onClick={() => {
                      takeAction('discoverMoreButton');
                    }}
                  ></ButtonV2>
                </a>
                <div className="flex justify-center items-center w-full  sm:mb-10 md:mt-8 ">
                  <div className="flex justify-center items-center h-32 w-32 ">
                    <a href="#Videos">
                      <img src={anchor} alt="anchor" />
                    </a>
                  </div>
                </div>
              </div>
            </>
            {/* HOW TO MEASURE */}
            <a id="Videos"></a>
            {isMobileView==false && (
            <div className="sm:hidden md:hidden flex left-0  absolute w-full items-center justify-center bg-darkslateblue">
              <HomePageVideoSwitchv2 lang={lang} align="right" parentPage={pageName} />
            </div>)
          }
            <div className="hidden sm:flex md:flex left-0  absolute w-full items-center justify-center bg-darkslateblue">
              <HomePageVideoSwitchMobile lang={lang} align="right"  parentPage={pageName} />
            </div>
            <div className="sm:min-h-[36rem] md:min-h-[38rem] mdSide:min-h-[21rem] lg:min-h-[28rem]"></div>

            {/* FIND US HERE */}
            <div className="flex w-full sm:pt-20 md:pt-24 mdSide:pt-12 lg:pt-24 mdSide:pb-12 lg:pb-12 md:items-center md:justify-center bg-almond">
              <div className="items-center justify-between w-full ">
                <HomePageSectionTitleComponent textColor="text-darkslateblue">
                  <Trans i18nKey="LP.findus.title.1a"></Trans>
                  <b className="home-page-section-title-bold">
                    <Trans i18nKey="LP.findus.title.1b"></Trans>
                  </b>
                </HomePageSectionTitleComponent>
                <div className="text-darkslateblue py-4 mb-16 sm:w-fit md:py-4 md:mb-16 md:text-xl mdSide:mb-8 mdSide:py-4 lg:mb-4 lg:py-8 mdSide:w-[28rem] lg:w-[28rem] w-[21.35rem] font-normal  text-lg">
                  <Trans i18nKey="LP.findus.title.info"></Trans>
                </div>
              </div>
            </div>

            <div className="sm:ml-[-2rem] sm:mr-[-2rem] md:ml-[-8rem] md:mr-[-12rem] mdSide:ml-[-12rem] mdSide:mr-[-12rem] ">
              <SliderComponent>
                <ClientCardComponent
                  page='LP_CTAButtonClicked'
                  ctaValue="baabuk_prim"
                  imageUrl={baabuk}
                  clientLogoUrl={baabuk_logo}
                  imageUrlMobile={baabuk_mobile}
                  targetUrl="https://www.baabuk.com/"
                  title="Baabuk"
                  firstElement={true}
                  description={t`LP.findus.baabuk`}
                />
                <ClientCardComponent
                  page='LP_CTAButtonClicked'
                  ctaValue="ricosta_prim"
                  imageUrl={ricosta}
                  clientLogoUrl={ricosta}
                  imageUrlMobile={ricosta_mobile}
                  targetUrl="http://www.ricosta.de/"
                  title="Ricosta"
                  description={t`LP.findus.ricosta`}
                />
              </SliderComponent>
            </div>

            <div className="sm:min-h-[6rem] md:min-h-[6rem] mdSide:min-h-[6rem] lg:min-h-[4rem]"></div>
            {/* Increase your sales */}
            <div className="flex left-0 absolute w-full items-start justify-start bg-[#cdd8dd]">
              <div className="flex md:hidden sm:hidden w-full mdSide:py-20 lg:py-24  home-page-place-holder-margin ">
                <img className="w-6 top-0 absolute" src={waves} alt="waves" />
                <div className="flex flex-col mt-6 mb-4 ">
                  <HomePageSectionTitleAltComponent textColor="text-darkslateblue">
                    <b className="home-page-section-title-bold">
                      <Trans i18nKey="LP.increase.sales"></Trans>
                    </b>
                    <Trans i18nKey="LP.increase.sales.footprint"></Trans>
                  </HomePageSectionTitleAltComponent>
                  <div className="py-2 text-darkslateblue text-xl flex font-normal items-baseline mdSide:whitespace-nowrap lg:whitespace-nowrap">
                    <Trans i18nKey="LP.increase.sales.savemoney"></Trans>{' '}
                    <img src={piggy_bank} className="ml-4 w-[2.18rem]" alt="piggy bank" />
                  </div>
                </div>
                <div className="flex w-1/2 items-center justify-end">
                  <a href="https://partners.footprinttech.de/" target='_blank'>
                    <ButtonV2
                      text={t`LP.increase.becomepartner`}
                      size="large-button"
                      enabled
                      type="full"
                      hasArrow={true}
                      arrowLightColor={true}
                      onClick={() => {
                        takeAction('becomePartnerButton');
                      }}
                    ></ButtonV2>
                  </a>
                </div>
              </div>
              {/* MOBILE Increase your sales */}
              <div className="lg:hidden mdSide:hidden items-center justify-center gap-4 w-full py-12 home-page-place-holder-margin">
                <img className="w-6 top-0 sm:left-4 md:left-[5.563rem] absolute" src={waves} alt="waves" />
                <div className="flex flex-col items-center justify-center text-darkslateblue text-2xl text-center font-title tracking-wide md:whitespace-nowrap">
                  <b className="home-page-section-title-bold">
                    <Trans i18nKey="LP.increase.sales"></Trans>
                  </b>
                  <Trans i18nKey="LP.increase.sales.footprint"></Trans>
                  <img src={piggy_bank} className="w-8 mb-8 mt-8 " alt="piggy bank" />
                  <div className="mb-8 text-darkslateblue text-base font-normal  ">
                    <Trans i18nKey="LP.increase.sales.savemoney"></Trans>
                  </div>
                  <a href="https://partners.footprinttech.de/" target='_blank'>
                    <ButtonV2
                      text={t`LP.increase.becomepartner`}
                      size="large-button"
                      enabled
                      type="full"
                      hasArrow={true}
                      arrowLightColor={true}
                      onClick={() => {
                        takeAction('becomePartnerButton');
                      }}
                    ></ButtonV2>
                  </a>
                </div>
              </div>
            </div>

            <div className="sm:min-h-[26rem] md:min-h-[24rem] mdSide:min-h-[21rem] lg:min-h-[28rem]"></div>

            <a id="explore"></a>
            {/* WE FIND YOUR FIT */}
            <div className="flex sm:hidden items-end justify-between md:mt-24 lg:mt-12 mdSide:mt-20">
              <div className="flex justify-start items-end ">
                <img src={hand} className="w-[35.75rem] lg:pr-12 mdSide:w-[29.188rem] md:w-[18.438rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
              <div className="flex flex-col  lg:gap-8 lg:ml-12 w-5/12 md:w-1/2 ">
                <HomePageSectionTitleComponent textColor="text-darkslateblue">
                  <Trans i18nKey="LP.fit.title.1a"></Trans>
                  <b className="home-page-section-title-bold py-4">
                    <Trans i18nKey="LP.fit.title.1b"></Trans>
                  </b>
                </HomePageSectionTitleComponent>
                <div className="flex flex-col md:gap-6 gap-12 mdSide:mt-16  md:mt-12 lg:mt-16 lg:mb-8  mdSide:mb-8 sm:w-4/5 md:w-full ">
                  <HomePageBulletInfoComponent
                    imageUrl={icon_footwear}
                    color="text-darkslateblue"
                    title={t`LP.fit.perfect-fit`}
                    description={t`LP.fit.perfect-fit.info`}
                  />
                  <HomePageBulletInfoComponent
                    imageUrl={icon_profiles}
                    color="text-darkslateblue"
                    title={t`LP.fit.create-profiles`}
                    description={t`LP.fit.create-profiles.info`}
                  />
                  <HomePageBulletInfoComponent
                    imageUrl={icon_co2}
                    color="text-darkslateblue"
                    title={t`LP.fit.shop-sustainably`}
                    description={t`LP.fit.shop-sustainably.info`}
                  />
                </div>
                <div className="lg:block mdSide:block md:hidden sm:hidden w-full">
                  <ButtonV2 text={t`LP.fit.explore-app`} size="large-button" enabled type="full" onClick={ExploreAppClicked}></ButtonV2>
                </div>
              </div>
            </div>
            <div className="lg:hidden mdSide:hidden md:block sm:hidden w-full justify-center items-center mt-16 mb-12 text-center">
              <ButtonV2 text={t`LP.fit.explore-app`} size="large-button" enabled type="full" onClick={ExploreAppClicked}></ButtonV2>
            </div>
            {/* MOBILE VIEW*/}
            <div className="flex flex-col sm:block md:hidden lg:hidden mdSide:hidden justify-between sm:mt-16">
              <HomePageSectionTitleComponent textColor="text-darkslateblue">
                <Trans i18nKey="LP.fit.title.1a"></Trans>
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.fit.title.1b"></Trans>
                </b>
              </HomePageSectionTitleComponent>
              <div className="flex h-[20rem]  w-full bg-darkslateblue mt-32 rounded-t-[50%]"></div>
              <div className="relative items-center flex flex-col w-full pb-5 pt-48 bg-darkslateblue rounded-b-[2rem] ">
                <img src={hand_mobile} className="absolute w-[20.75rem]  -translate-y-full" alt="Get the correct shoe size for your chosen shoe with Footprint" />
                <div className="flex flex-col gap-16 p-4 w-11/12 rounded-b-[2rem] rounded-t-[2rem]   bg-polly">
                  <div className="flex flex-col gap-8 items-center">
                    <HomePageBulletInfoComponent
                      imageUrl={icon_footwear_light}
                      color="text-almond"
                      title={t`LP.fit.perfect-fit`}
                      description={t`LP.fit.perfect-fit.info`}
                    />
                    <HomePageBulletInfoComponent
                      imageUrl={icon_profiles_light}
                      color="text-almond"
                      title={t`LP.fit.create-profiles`}
                      description={t`LP.fit.create-profiles.info`}
                    />
                    <HomePageBulletInfoComponent
                      imageUrl={icon_co2_light}
                      color="text-almond"
                      title={t`LP.fit.shop-sustainably`}
                      description={t`LP.fit.shop-sustainably.info`}
                    />
                    <ButtonV2
                      text={t`LP.fit.explore-app`}
                      size="large-light-button"
                      enabled
                      type="empty"
                      onClick={ExploreAppClicked}
                    ></ButtonV2>
                  </div>
                </div>
              </div>
            </div>

            {/* OUR WHY */}
            <div className="mt-24 lg:w-1/2 w-3/2  sm:w-full">
              <HomePageSectionTitleComponent textColor="text-darkslateblue">
                <Trans i18nKey="LP.our.why.title.1a"></Trans>
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.our.why.title.1b"></Trans>
                </b>
              </HomePageSectionTitleComponent>
              <div className="home-page-info-text text-darkslateblue w-3/4 sm:w-full mt-6">
                <Trans i18nKey="LP.our.why.info"></Trans>
              </div>
            </div>
            <div className="gap-6 w-full lg:mt-8 mb-16 sm:mb-32 flex sm:flex-col mt-10 items-center justify-start">
              <WhiteCardComponent
                title={t`LP.our.why.yearly`}
                info={t`LP.our.why.mil`}
                description={t`LP.our.why.returns`}
                icon={icon_shipping}
              ></WhiteCardComponent>
              <WhiteCardComponent
                title={t`LP.our.why.yearly`}
                info="336T"
                hasCO2
                description={t`LP.our.why.emissions`}
                icon={icon_cloud_globe}
              ></WhiteCardComponent>
            </div>
            {/* YOUR CALCULATOR */}
            <div className="flex left-0 absolute w-full items-start justify-start bg-darkslateblue">
              <div
                className={`flex w-full mdSide:py-20 md:mb-0 sm:mt-8 ${
                  isSmallLG ? 'lg:mb-0 lg:mt-6 lg:py-12' : ' lg:py-20'
                } home-page-place-holder-margin `}
              >
                <div className="gap-6 sm:gap-0 w-full flex sm:flex-col items-center justify-start">
                  <div className="flex w-1/2 sm:w-full flex-col">
                    <HomePageSectionTitleComponent textColor="text-almond">
                    <Trans i18nKey="LP.calculator.your"></Trans>
                      <b className="home-page-section-title-bold"><Trans i18nKey="LP.calculator.calc"></Trans></b>
                    </HomePageSectionTitleComponent>
                    <div className="home-page-info-text text-almond w-2/3 sm:w-full mt-6">
                    <Trans i18nKey="LP.calculator.calcInfo"></Trans>
                    </div>
                  </div>
                  <div className="flex w-1/2 sm:w-full">
                    <HomePageCoCalculatorComponent onClick={ExploreStoresClicked} />
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:min-h-[42rem] md:min-h-[32rem] mdSide:min-h-[38rem] lg:min-h-[32rem]"></div>
            {/* TRUSTED BY MANY */}
            <div className="flex flex-col gap-8 pt-12 lg:pt-32">
              <HomePageSectionTitleComponent textColor="text-darkslateblue">
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.trusted"></Trans>
                </b>
                <Trans i18nKey="LP.by.many"></Trans>
              </HomePageSectionTitleComponent>
              <SquareCards cardSize={window.innerWidth <= 600 ? 296 : 400}>
                <HomePageCarouselCardComponent
                  title={t`LP.testimonials.a.title`}
                  sign={t`LP.testimonials.a.sign`}
                  description={t`LP.testimonials.a.info`}
                ></HomePageCarouselCardComponent>
                <HomePageCarouselCardComponent
                  title={t`LP.testimonials.b.title`}
                  sign={t`LP.testimonials.b.sign`}
                  description={t`LP.testimonials.b.info`}
                ></HomePageCarouselCardComponent>
                <HomePageCarouselCardComponent
                  title={t`LP.testimonials.c.title`}
                  sign={t`LP.testimonials.c.sign`}
                  description={t`LP.testimonials.c.info`}
                ></HomePageCarouselCardComponent>
                <HomePageCarouselCardComponent
                  title={t`LP.testimonials.d.title`}
                  sign={t`LP.testimonials.d.sign`}
                  description={t`LP.testimonials.d.info`}
                ></HomePageCarouselCardComponent>
                <HomePageCarouselCardComponent
                  title={t`LP.testimonials.e.title`}
                  sign={t`LP.testimonials.e.sign`}
                  description={t`LP.testimonials.e.info`}
                ></HomePageCarouselCardComponent>
              </SquareCards>
            </div>

            <a id="findyourperfectfit"></a>
            {/* FIND YOUR PERFECT FIT */}
            <div className="flex flex-col gap-12 py-20  ">
              <div className="hidden lg:block">
                <HomePageSectionTitleComponent textColor="text-darkslateblue">
                  <Trans i18nKey="LP.find.your.perfect"></Trans>
                  <b className="home-page-section-title-bold">
                    <Trans i18nKey="LP.find.fit"></Trans>
                  </b>
                </HomePageSectionTitleComponent>
                <div className="home-page-info-text text-center  text-darkslateblue mt-6 md:w-1/2">
                  <Trans i18nKey="LP.find.fit.info"></Trans>
                </div>
              </div>
              <div className="lg:hidden flex flex-col">
                <HomePageSectionTitleComponent textColor="text-darkslateblue">
                  <Trans i18nKey="LP.find.your.perfect"></Trans>
                  <b className="home-page-section-title-bold">
                    <Trans i18nKey="LP.find.fit"></Trans>
                  </b>
                </HomePageSectionTitleComponent>
                <div className="home-page-info-text   text-darkslateblue mt-3 md:w-2/5 ">
                  <Trans i18nKey="LP.find.fit.info"></Trans>
                </div>
              </div>
              <div className="sm:ml-[-2rem] sm:mr-[-2rem] md:ml-[-8rem] md:mr-[-12rem] mdSide:ml-[-12rem] mdSide:mr-[-12rem] ">
                <SliderComponent>
                  <HomePageClientCardPerfectFitComponent
                    page='LP_CTAButtonClicked'
                    ctaValue="baabuk_sec"
                    imageUrl={baabuk_perfectfit}
                    externalLinkIcon={externalLinkIcon}
                    targetUrl="https://www.baabuk.com/"
                    title=""
                    firstElement={true}
                  />
                  <HomePageClientCardPerfectFitComponent
                    page='LP_CTAButtonClicked'
                    ctaValue="ricosta_sec"
                    imageUrl={ricosta_perfectfit}
                    externalLinkIcon={externalLinkIcon}
                    targetUrl="https://www.ricosta.de/"
                    title=""
                  />
                </SliderComponent>
              </div>
            </div>

            <div className="flex left-0 right-0 absolute w-full items-start justify-start bg-[#1b1c1e]">
              <div className="flex w-full mdSide:py-12 lg:py-24  home-page-place-holder-margin ">
                <FooterComponent parentPage={pageName} parentPage_linkId="home_page"></FooterComponent>
              </div>
            </div>

            <GdprComponent onAccept={(consent) => onAccept(consent)} gdprView={showGdpr}></GdprComponent>
          </PageHolderComponent>
        </motion.div>
      ) : (
        <div className="floating-loader">
          <img src={LogoDark} className="w-[3.544rem] h-[2.492rem] circle" alt="Get the correct shoe size for your chosen shoe with Footprint" />
        </div>
      )}
    </>
  );
};

export default HomePage;
