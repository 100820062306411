import './assets/styles/index.css';
import { useCookies } from 'react-cookie';
import { initialState } from './context/AppState';

import SizeGuideComponent from './components/common/sizeGuide/SizeGuideComponent';
import AppContextService from './context/AppContextService';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../src/components/pages/home/HomePage';
import UserTestingPage from './components/pages/userTesting/UserTestingPage';
import EmailVerifiedPage from './components/pages/emailVerified/emailVerifiedPage';
import ExploreApp from './components/pages/home/ExploreApp';
import PrivacyPolicy from './components/pages/privacyPolicy/PrivacyPolicy';
import Imprint from './components/pages/imprint/Imprint';
import Demo from './components/pages/demo/Demo';

const App = ({ onLanguageChange }) => {
  // Segment has to be loaded ahead
  const [loadedAnalytics, setLoadedAnalytics] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies();
  const env = process.env.REACT_APP_ENV_NAME;
  const showBreakPoints = env === 'dev' || env === 'dev-local';
  const contextService = AppContextService.getInstance(initialState);

  // check if gdpr consent exists
  if (cookies['fpt-technical'] || cookies['fpt-technical']) {
    const technical = cookies['fpt-technical'] ? true : false;
    const analytics = cookies['fpt-analytics'] ? true : false;
    contextService.set('gdpr', { technical, analytics });
    console.log(`[gdpr] loading... [t: ${technical} a: ${analytics}]`);
  } else {
    console.log(`[gdpr] loading... [no consent]`);
    contextService.set('gdpr', null);
  }

  useEffect(() => {
  
  });

  const routes = () => (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage onLanguageChange={onLanguageChange} />} />
        <Route path="/Explore-Our-App" element={<ExploreApp onLanguageChange={onLanguageChange} />} />
        <Route path="/Shape-Footprint" element={<UserTestingPage onLanguageChange={onLanguageChange} />} />
        <Route path="/emailVerified" element={<EmailVerifiedPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy onLanguageChange={onLanguageChange} />} />
        <Route path="/imprint" element={<Imprint onLanguageChange={onLanguageChange} />} />
        <Route path="/demo" element={<Demo onLanguageChange={onLanguageChange} />} />
      </Routes>
    </Router>
  );

  return (
    <>
      <SizeGuideComponent show={showBreakPoints} />
      {routes()}
    </>
  )  
};

export default App;
