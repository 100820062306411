import AppContextService from '../context/AppContextService';

export class AnalyticsService {
  private static instance: AnalyticsService;
  private static gdprCookies: { technical: boolean; analytics: boolean };
  private static contextService: AppContextService;

  public static loaded: boolean;
  private static onLoaded: Promise<any>;

  public static track(event: string, data: any, force:boolean=false) {
    return false;
  }

  public static page(event: string, data: any, force:boolean=false) {
    return false;
  }
  public static identify(userId: string, data: any, force:boolean=false) {
    return false;
  }

  public static group(data: any) {
    return false;
  }

  public static getAnonymousId() {
    return this.onLoaded
      .then((analytics) => analytics.user().anonymousId())
      .catch((err) => console.log('[AnalyticsService] Analytics service is not available', err));
  }
}
