import { arrow_right, arrow_right_dark, loader_three_dots } from '../../../assets/images';
import './Buttonv2.css';

export type ButtonV2Type = 'empty' | 'border-light' | 'border-dark' | 'full' | 'border-none' | 'white-bg';
export type ButtonV2Size =
  | 'large-button'
  | 'small-button'
  | 'mid-button'
  | 'large-text-button'
  | 'large-light-button'
  | 'large-white-button';

interface ButtonV2Props {
  text: string;
  type: ButtonV2Type;
  size: ButtonV2Size;
  enabled: boolean;
  loading?: boolean;
  hasArrow?: boolean;
  arrowLightColor?: boolean;
  onClick(): any;
}

const ButtonV2: React.FunctionComponent<ButtonV2Props> = (props) => {
  const buttonClass = () => {
    switch (props.type) {
      case 'empty':
        return `Buttonv2 Light-Button ${props.size}`;
      case 'border-light':
        return `Buttonv2 Light-Border-Button ${props.size}`;
      case 'full':
        return `Buttonv2 Dark-Button ${props.size}`;
      case 'border-dark':
        return `Buttonv2 Trans-Button ${props.size}`;
      case 'border-none':
        return `Buttonv2 Light-No-Border-Button ${props.size}`;
      case 'white-bg':
        return `Buttonv2 White-No-Border-Button ${props.size}`;
    }
  };

  const buttonContent = () => {
    if (props.loading) {
      return <img src={loader_three_dots} alt="loading" />;
    }
    return <span className="w-full whitespace-nowrap">{props.text}</span>;
  };

  return (
    <button className={buttonClass()} onClick={() => props.onClick()} disabled={!props.enabled}>
      <div className="flex">
        {buttonContent()}
        {props.hasArrow && <img src={props.arrowLightColor ? arrow_right : arrow_right_dark} className="pr-10 sm:w-[3rem]" alt="arrow" />}
      </div>
    </button>
  );
};

export default ButtonV2;
