import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import EmailIcon from '../../../assets/images/email.png';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';
import '../../../assets/styles/index.css';
import './emailVerifiedPage.css';
import ButtonV2 from '../../common/buttonv2/Buttonv2';

const EmailVerifiedPage: React.FunctionComponent = (props: any) => {
  const SegmentPageName = 'Email Verify Page';
  const { t } = useTranslation();
  const UMA_URL = process.env.REACT_APP_UMA_URL;
  const LP_API_KEY = process.env.REACT_APP_LP_API_KEY;

  useEffect(() => {
    document.title = t`LP.signup.email.verified-title`;
    AnalyticsService.track(SegmentPageName, {
      action: SA[SA.Viewed],
    });
  });
  const dummyAction = () => {
    return null;
  };
  return (
    <>
      <div className="fpt-c-email-verified-page">
        <div className="fpt-c-email-verified-page-icon">
          <img src={EmailIcon} alt="email" />
        </div>
        <h1 className="fpt-c-email-verified-page-title mb-4">
          <Trans i18nKey="LP.signup.email.verified-title"></Trans>
        </h1>
        <div className="fpt-c-email-verified-page-text">
          <Trans i18nKey="LP.signup.email.verified-text"></Trans>
        </div>{' '}
        <div className="fpt-c-email-verified-page-action">
          <a href={`${UMA_URL}/SignIn?apiKey=${LP_API_KEY}`}>
            <ButtonV2 text={t`LP.signup.email.verified-button`} size="large-text-button" enabled type="full" onClick={dummyAction}></ButtonV2>
          </a>
        </div>
        <p className="fpt-c-link-p">
          <a
            href="https://myfootprint.app/privacy-policy"
            className="fpt-c-link-text"
            target="blank"
          >
            <Trans i18nKey="LP.common.privacyPolicy"></Trans>
          </a>
        </p>
        <p className="fpt-c-link-p">
          <a href="https://myfootprint.app/imprint/" target="_blank" rel="noreferrer" className="fpt-c-link-text">
            <Trans i18nKey="LP.common.imprint"></Trans>
          </a>
        </p>
      </div>
    </>
  );
};

export default EmailVerifiedPage;
