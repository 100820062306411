import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import { Trans, useTranslation } from 'react-i18next';
import HomePageSectionTitleComponent from './HomePageSectionTitle';
import { motion } from 'framer-motion';
import YouTube, { YouTubeProps } from 'react-youtube';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';

interface HomePageVideoSwitchProps {
  lang: string;
  parentPage:string;
  align?: string;
}

const HomePageVideoSwitchMobile: React.FunctionComponent<HomePageVideoSwitchProps> = ({ lang,parentPage, align }) => {
  const [videoAgeGroup, setVideoAgeGroup] = useState('children');
  const [isSmallLG, setIsSmallLG] = useState(false);
  const [centerOfScreen, setCenterOfScreen] = useState(0);
  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);
  const playerRef4 = useRef(null);
  const playerRef5 = useRef(null);
  const { t } = useTranslation();
  const eventName = 'VideoInteraction';
  const [trigger, setTrigger] = useState(false);

  const tabs = [
    { name: t`LP.howto.children`, color: '#1e4e5f' },
    { name: t`LP.howto.adults`, color: '#1e4e5f' },
  ];
  const [selected, setSelected] = useState(0);
  const [formerColor, setFormerColor] = useState(tabs[0].color);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const takeAction = async (userAction, video_id, pause_time) => {
    if (pause_time != null) {
      AnalyticsService.track(eventName, {
        action: userAction,
        videoId: video_id,
        pauseTime: pause_time,
        page: parentPage,
        channel: 'Landing Page',
      });
    } else {
      AnalyticsService.track(eventName, {
        action: userAction,
        videoId: video_id,
        page: parentPage,
        channel: 'Landing Page',
      });
    }
    return null;
  };
  const handlePlay = (ageGroup) => {
    takeAction('Played', ageGroup, null);
  };
  const handlePause = (ageGroup, pRef) => {
    const player = pRef.current.internalPlayer;
    player.getCurrentTime().then((currentTime) => {
      takeAction('Paused', ageGroup, currentTime);
    });
    console.log('PAUSED');
  };
  const handleEnd = (ageGroup) => {
    takeAction('Ended', ageGroup, null);
  };
  const switchVideo = async (ageGroup) => {
    const iframeC = document.getElementById('movieChildMobile') as HTMLIFrameElement;
    const iframeA = document.getElementById('movieAdultMobile') as HTMLIFrameElement;
    if (iframeC && iframeC.contentWindow) {
      iframeC.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
    if (iframeA && iframeA.contentWindow) {
      iframeA.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    setVideoAgeGroup(ageGroup);
    return null;
  };

  useEffect(() => {
    console.log(isSmallLG);
  }, []);

  // Function to update container width
  const updateContainerWidth = () => {
    if (containerRef1.current) {
      setCenterOfScreen((window.innerWidth * (window.innerWidth < 521 ? 0.84 : 0.86)) / 2 - containerRef1.current.clientWidth / 2);
    }
  };

  // Set up an effect to update container width whenever the trigger changes
  useEffect(() => {
    updateContainerWidth();
  }, [trigger]);

  // Set up a mutation observer to watch for changes in the container's children
  useEffect(() => {
    if (containerRef1.current) {
      const observer = new MutationObserver(() => {
        setTrigger((prev) => !prev);
      });

      observer.observe(containerRef1.current, { childList: true, subtree: true });

      // Clean up the observer when the component unmounts
      return () => {
        observer.disconnect();
      };
    }
  }, [containerRef1]);
  // Set up an effect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      // Force the browser to recalculate the layout after a small delay
      setTimeout(() => {
        updateContainerWidth();
      }, 100);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="flex w-full py-12 mdSide:py-12 lg:py-24 home-page-place-holder-margin mx-auto">
        <div className="w-full sd:flex-col md:flex-col lg:flex mdSide:flex ">
          <div className="flex lg:ml-24  flex-col w-full mx-auto sm:text-center md:text-center z-10">
            {align === 'right' ? (
              <div className="lg:text-[3.125rem]   text-almond tracking-wide mb-8 ">
                <HomePageSectionTitleComponent textColor="text-almond">
                  <b className="home-page-section-title-bold">
                    <Trans i18nKey="LP.howto.title.1a"></Trans>
                  </b>
                  <Trans i18nKey="LP.howto.title.1b"></Trans>.
                </HomePageSectionTitleComponent>
                <div className="mt-4 mdSide:w-72 lg:w-96 text-almond lg:text-xl text-base font-normal  sm:text-center md:text-center">
                  <Trans i18nKey="LP.howto.title.info"></Trans>
                </div>
              </div>
            ) : (
              <div className="lg:text-[3.125rem] text-[1.875rem]  text-almond font-title tracking-wide mb-8   whitespace-nowrap">
                <Trans i18nKey="LP.explore.footprintFor"></Trans>
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.explore.anyone"></Trans>
                </b>
              </div>
            )}
            <div className="flex gap-12 md:justify-center">
              <div className="videoOptionsContainer  ml-4 mr-4 mt-16 mdSide:mt-20 lg:mt-24">
                {tabs.map(({ name, color }, i) => (
                  <motion.div
                    className="videoOptionsTab whitespace-nowrap"
                    key={i}
                    initial={{ opacity: 0.99, color: i === selected ? '#fdf5ec' : '#1e4e5f' }}
                    animate={{ opacity: 0.99, color: i === selected ? '#fdf5ec' : '#1e4e5f' }}
                    onTap={() => {
                      setFormerColor(tabs[selected].color);
                      setSelected(i);
                      switchVideo(i === 0 ? 'children' : 'adult');
                    }}
                  >
                    {name}
                    {i === selected && (
                      <motion.div
                        className="videoOptionSelection"
                        layoutId="selected"
                        style={{ borderRadius: 300 }}
                        initial={{ backgroundColor: formerColor, opacity: i === selected ? 0.99 : 0 }}
                        animate={{ backgroundColor: color, opacity: i === selected ? 0.99 : 0 }}
                        // transition={{ duration: 2 }}
                      />
                    )}
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ position: 'relative' }} className="w-full  md:h-[16rem] md:mt-12 sm:h-[14rem] sm:mt-8 items-center justify-center">
            <motion.div
              ref={containerRef1}
              className="sm:w-[21.375rem] sm:h-[13.375rem]  md:w-[25.375rem] md:h-[16.063rem]   overflow-hidden h-full rounded-2xl"
              style={{ position: 'absolute', top: 0, left: centerOfScreen }}
              animate={videoAgeGroup === 'children' ? { x: 0, opacity: 100 } : { x: -1800, opacity: 0 }}
            >
              <YouTube
                videoId={lang === 'en' ? 'oTMGyeoCqX8' : '-TAwALg6FkI'}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    rel: 0,
                    showinfo: 0,
                  },
                }}
                ref={playerRef4}
                className="w-full mx-auto sm:w-[21.375rem] sm:h-[13.375rem]  md:w-[25.375rem] md:h-[16.063rem]"
                id="movieChildMobile"
                onReady={onPlayerReady}
                onPlay={() => handlePlay('child')}
                onPause={() => handlePause('child', playerRef4)}
                onEnd={() => handleEnd('child')}
              />
            </motion.div>
            <motion.div
              ref={containerRef2}
              className="sm:w-[21.375rem] sm:h-[13.375rem]  md:w-[25.375rem] md:h-[16.063rem]  overflow-hidden h-full rounded-2xl"
              style={{ position: 'absolute', top: 0, left: centerOfScreen }}
              animate={videoAgeGroup !== 'children' ? { x: 0, opacity: 100 } : { x: -1800, opacity: 0 }}
            >
              <YouTube
                videoId={lang === 'en' ? 'jJhYU4XZSRQ' : 'nunpNaNH-s4'}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    rel: 0,
                    showinfo: 0,
                  },
                }}
                ref={playerRef5}
                className="w-full mx-auto sm:w-[21.375rem] sm:h-[13.375rem]  md:w-[25.375rem] md:h-[16.063rem]  "
                id="movieAdultMobile"
                onPlay={() => handlePlay('adult')}
                onPause={() => handlePause('adult', playerRef5)}
                onEnd={() => handleEnd('adult')}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePageVideoSwitchMobile;
