import { Children, useState, isValidElement, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { arrowLeft, arrowRight } from '../../../assets/images';

const SquareCards = ({ children, cardSize }) => {
  const [[activeIndex, direction], setActiveIndex] = useState([0, 0]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);

  // convert the children to an array and filter out any non-element children
  const childArray = Children.toArray(children).filter((child) => isValidElement(child));

  // we want the scope to be always to be in the scope of the array so that the carousel is endless
  const indexInArrayScope = ((activeIndex % childArray.length) + childArray.length) % childArray.length;

  // so that the carousel is endless, we need to repeat the children twice
  // then, we slice the the array so that we only have 3 children visible at the same time
  const visibleChildren = [...childArray, ...childArray].slice(indexInArrayScope, indexInArrayScope + 5);
  const handleClick = (newDirection) => {
    setActiveIndex((prevIndex) => [prevIndex[0] + newDirection, newDirection]);
  };

  // calculate the position of the centered card

  window.addEventListener('resize', handleResize);
  function handleResize() {
    const divElement = document.querySelector('.main-wrapperx');
    const visibleWidth = divElement?.clientWidth;
    if (visibleWidth) {
      setContainerWidth(visibleWidth);
    }
    const divElement2 = document.querySelector('.card');
    const visibleWidth2 = divElement2?.clientWidth;
    if (visibleWidth2) {
      setCardWidth(visibleWidth2);
    }
  }
  useEffect(() => {
    handleResize();
  }, []);

  return (
    <>
      <div className="main-wrapperx">
        <div className="wrapperx" style={{ position: 'relative' }}>
          <div
            className="buttons-absolute "
            style={{ position: 'absolute', zIndex: 100, top: '50%', left: containerWidth / 2 - cardWidth / 2 - 70 }}
          >
            <motion.div whileTap={{ scale: 0.8 }} onClick={() => handleClick(-1)}>
              <img src={arrowLeft} alt="arrow" />
            </motion.div>
          </div>
          {/* Add another div element to position the other button */}
          <div
            className="buttons-absolute"
            style={{ position: 'absolute', zIndex: 100, top: '50%', right: containerWidth / 2 - cardWidth / 2 - 70 }}
          >
            <motion.div whileTap={{ scale: 0.8 }} onClick={() => handleClick(1)}>
              <img src={arrowRight} alt="arrow" />
            </motion.div>
          </div>
          {/*AnimatePresence is necessary to show the items after they are deleted because only max. 5 are shown*/}
          <AnimatePresence mode="popLayout" initial={false}>
            {visibleChildren.map((child, index) => {
              // The layout prop makes the elements change its position as soon as a new one is added
              // The key tells framer-motion that the elements changed its position
              const key = uuidv4();
              const position =
                index === 0 ? 'leftmost' : index === 1 ? 'left' : index === 2 ? 'center' : index === 3 ? 'right' : 'rightmost';

              return (
                <motion.div
                  key={key}
                  className="card"
                  layout
                  custom={{
                    direction,
                    position,
                  }}
                  variants={variants(cardWidth, containerWidth)}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ duration: 1 }}
                  style={{
                    width: cardSize,
                    position: 'relative',
                  }}
                  presence={index === 1 ? 'true' : 'false'}
                  {...(index === 1 && { style: { display: 'block' } })} // optionally hide the element that is leaving
                  // add an index signature to allow any additional prop
                  {...(child as any)}
                >
                  {child}
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

const variants = (cardSize, containerWidth) => {
  return {
    enter: ({ direction }) => {
      return { scale: 0.2, x: direction < 1 ? cardSize / 2 : -cardSize / 2, opacity: 0 };
    },
    center: ({ position, direction }) => {
      return {
        scale: position === 'center' ? 1 : 0.8,
        x: -20 + (containerWidth / 2 - (cardSize * 5) / 2),
        zIndex: getZIndex({ position, direction }),
        opacity: position === 'center' ? 1 : 0.4,
      };
    },
    exit: ({ direction }) => {
      return { scale: 0.2, x: direction < 1 ? -cardSize / 2 : cardSize / 2, opacity: 0 };
    },
  };
};
function getZIndex({ position, direction }) {
  const indexes = {
    leftmost: direction > 0 ? 4 : 1,
    left: direction > 0 ? 3 : 2,
    center: 5,
    right: direction > 0 ? 2 : 3,
    rightmost: direction > 0 ? 1 : 4,
  };
  return indexes[position];
}

export default SquareCards;
