import { t } from 'i18next';
import { icon_earth, icon_info } from '../../../assets/images';
import ButtonV2 from '../../common/buttonv2/Buttonv2';
import Slider from '../../common/slider/Slider';
import { motion, AnimatePresence } from 'framer-motion';
import './HomePageCoCalculator.css';
import { AnalyticsService } from '../../../services/AnalyticsService';

import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { SA } from '../../../models/AState';

interface HomePageWhiteCardProps {
  onClick(): any;
}

const HomePageCoCalculatorComponent: React.FunctionComponent<HomePageWhiteCardProps> = ({ onClick }) => {
  const [sliderValue, setSliderValue] = useState(6);
  const [isFlipped, setIsFlipped] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [contentChanging, setContentChanging] = useState(false);
  const [calculatedResult, setCalculatedResult] = useState(0);
  const [footprintEquivalent, setFootprintEquivalent] = useState('');
  const [avarageReturn, setAvarageReturn] = useState(0);
  const pageName = 'Homepage';

  const CO2_EMISSIONS_PER_SHOE = 600;
  const CO2_EMISSIONS_PER_RETURN = 600;

  const questions = [
    {
      title: t`LP.calculator.question1`,
      step: t`LP.calculator.title1`,
      defaultValue: 6,
      minValue: 1,
      maxValue: 12,
      valueType: t`LP.calculator.type1`,
      subinfo: t`LP.calculator.timespan1`,
    },
    {
      title: t`LP.calculator.question2`,
      step: t`LP.calculator.title2`,
      defaultValue: 3,
      minValue: 1,
      maxValue: 5,
      valueType: t`LP.calculator.type2`,
      subinfo: t`LP.calculator.timespan2`,
    },
    {
      title: t`LP.calculator.question3`,
      step: t`LP.calculator.title3`,
      defaultValue: 2,
      minValue: 0,
      maxValue: 12,
      valueType: t`LP.calculator.type3`,
      subinfo: t`LP.calculator.timespan3`,
    },
    {
      title: t`LP.calculator.question4`,
      step: t`LP.calculator.title4`,
      defaultValue: 0,
      valueType: t`LP.calculator.type4`,
      subinfo: t`LP.calculator.timespan4`,
    },
  ];

  const numberWithThousandsSeparator = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const getFootprintEquivalent = (carbonFootprint: number) => {
    if (carbonFootprint <= 1200) {
      return t`LP.calculator.result1`;
    } else if (carbonFootprint <= 2400) {
      return t`LP.calculator.result2`;
    } else if (carbonFootprint <= 3600) {
      return t`LP.calculator.result3`;
    } else if (carbonFootprint <= 4800) {
      return t`LP.calculator.result4`;
    } else if (carbonFootprint <= 6000) {
      return t`LP.calculator.result5`;
    } else if (carbonFootprint <= 7200) {
      return t`LP.calculator.result6`;
    } else {
      return t`LP.calculator.result6`;
    }
  };

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
  };
  const Co2Answered = (questionNo,answer) => {
    AnalyticsService.track("LP_CalculatorQuestionAnswered", {
      page: pageName, 
      buttonId :"co2InfoButton",
      questionNumber: questionNo + 1,
      answer: answer + (questionNo == 0 ? " times" : " pairs"),
    });
  }
  const Co2Completed = (CO2Footprint) => {
    AnalyticsService.track("LP_CalculatorCompleted", {
      page: pageName, 
      buttonId :"co2InfoButton",
      CO2Footprint: CO2Footprint,
    });
  }

  const Co2InfoClicked = () => {
   AnalyticsService.track("LP_CalculatorInfoClicked", {
    page: pageName, 
    buttonId :"co2InfoButton", 
    questionNumber: currentStep + 1,
  });
  }

  const handleNextClick = () => {
    if (currentStep === 1) {
      let avg = Math.floor(answers[0] / 2);
      if (avg > 2) {
        avg = 2;
      }
      questions[2].defaultValue = avg;
      setAvarageReturn(avg);
    }

    if (currentStep < 3) {
      setContentChanging(true);
      const newAnswers = [...answers];
      newAnswers[currentStep] = sliderValue;
      setAnswers(newAnswers);

      setCurrentStep((prevStep) => prevStep + 1);
      setSliderValue(questions[currentStep + 1].defaultValue);

      const carbonFootprint = calculateCarbonFootprint(newAnswers);
      const footprintEquivalent = getFootprintEquivalent(carbonFootprint);
      setFootprintEquivalent(footprintEquivalent);
      setCalculatedResult(carbonFootprint);
      Co2Answered(currentStep,sliderValue);
      if (currentStep ==2) {
        setTimeout(() => {
          Co2Completed(carbonFootprint)  
        }, 500);
      }
    } else {
      setContentChanging(true);
      setCurrentStep(0);
      setSliderValue(questions[0].defaultValue);
    }

   
  };
  const calculateCarbonFootprint = (answers: number[]) => {
    const shoesPurchased = answers[0]; //* answers[1];
    const shoesReturned = answers[2];

    return CO2_EMISSIONS_PER_SHOE * shoesPurchased + CO2_EMISSIONS_PER_RETURN * shoesReturned;
  };

  const contentVariants = {
    hidden: (custom) => ({
      x: custom * 500,
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.3 },
    },
    exitLeft: {
      x: '-100%',
      opacity: 0,
      transition: { duration: 0.3 },
    },
  };

  const [answers, setAnswers] = useState([questions[0].defaultValue, questions[1].defaultValue, questions[2].defaultValue]);

  return (
    <div className="co_calculator_scene ">
      <div className={`co_calculator_card flex items-center justify-center ${isFlipped ? 'flipped' : ''}`}>
        <div className="co_calculator_card_content co_calculator_front">
          <div className="flex w-full mb-6 ">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentStep}
                custom={currentStep}
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                exit="exitLeft"
                onAnimationComplete={() => {
                  if (contentChanging) {
                    setContentChanging(false);
                  }
                }}
                className="text-[0.875] md:text-[0.75] lg:text-xl text-darkslateblue w-full flex items-center"
              >
                <div className="flex flex-col lg:py-3  w-full  mdSide:w-[29.125rem] lg:w-full lg:max-w-[35.75rem]  bg-white rounded-[2rem]  ">
                  <div className="flex justify-between items-center  pt-6 pb-4 px-7 lg:px-7 ">
                    <div className="text-[0.875] md:text-[0.75] lg:text-xl text-darkslateblue flex items-center">
                      <img src={icon_earth} className="w-[1.5rem] h-[1.5rem] lg:w-[2.188rem] lg:h-[2.188rem]" alt="icon_earth" />
                      <div className="text-sm justify-center items-center ml-2 ">{questions[currentStep].step}</div>
                    </div>
                    <img
                      src={icon_info}
                      className="w-[1.5rem] h-[1.5rem] lg:w-[2.188rem] lg:h-[2.188rem] cursor-pointer"
                      onClick={() => {setIsFlipped(!isFlipped);Co2InfoClicked();}}
                      alt="icon_info"
                    />
                  </div>
                  <div className="flex pt-0 px-7 text-base font-bold min-h-[3rem] items-center text-darkslateblue">
                    {questions[currentStep].title}
                  </div>
                  <div className="flex flex-col pt-0  pb-2 px-7  ">
                    <div className="leading-[2rem] lg:leading-[3rem] pb-4 text-[2.188rem] lg:text-[3.813rem] text-darkslateblue font-bold"></div>
                    {questions[currentStep].defaultValue !== 0 ? (
                      <Slider
                        min={questions[currentStep].minValue}
                        max={currentStep !== 2 ? questions[currentStep].maxValue : answers[0]}
                        initialValue={currentStep !== 2 ? questions[currentStep].defaultValue : avarageReturn}
                        onChange={handleSliderChange}
                      />
                    ) : (
                      <div className="co_result">
                        {numberWithThousandsSeparator(calculatedResult)}g <span className="co_co2">co2</span>
                      </div>
                    )}
                  </div>
                  {questions[currentStep].defaultValue !== 0 ? (
                    <div className="flex flex-col items-center">
                      <div className="text-[1.375rem] font-bold text-darkslateblue">
                        {sliderValue} {questions[currentStep].valueType}
                      </div>
                      <div className="text-darkslateblue text-[0.875rem]">{questions[currentStep].subinfo}</div>
                    </div>
                  ) : (
                    <div className="flex flex-col pt-0  pb-2 px-7  ">
                      <div className="text-darkslateblue">{footprintEquivalent}</div>
                    </div>
                  )}
                  <div className="flex flex-col items-center mt-6 ">
                    {questions[currentStep].defaultValue !== 0 ? (
                      <ButtonV2
                        text={t`LP.common.next`}
                        size="mid-button"
                        arrowLightColor={false}
                        enabled
                        type="border-dark"
                        hasArrow={true}
                        onClick={handleNextClick}
                      ></ButtonV2>
                    ) : (
                      <div className="mt-6">
                        <ButtonV2
                          text={t`LP.calculator.cta`}
                          size="large-button"
                          enabled
                          type="full"
                          arrowLightColor={false}
                          hasArrow={false}
                          onClick={onClick}
                        ></ButtonV2>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <div className="co_calculator_card_content co_calculator_back">
          <div className="flex w-full ">
            <div className="flex flex-col lg:py-3  w-full  mdSide:w-[29.125rem] lg:w-full lg:max-w-[35.75rem]  bg-white rounded-[2rem]  ">
              <div className="flex justify-between items-center pt-6 pb-4 px-7 lg:px-7 ">
                <div className="text-[0.875] md:text-[0.75] lg:text-xl text-darkslateblue flex items-center">
                  <img src={icon_earth} className="w-[1.5rem] h-[1.5rem] lg:w-[2.188rem] lg:h-[2.188rem]" alt="icon_earth" />
                  <div className="text-sm justify-center items-center ml-2 ">
                    <Trans i18nKey="LP.calculator.infoTitle"></Trans>
                  </div>
                </div>
                <img
                  src={icon_info}
                  className="w-[1.5rem] h-[1.5rem] lg:w-[2.188rem] lg:h-[2.188rem] cursor-pointer"
                  onClick={() => setIsFlipped(!isFlipped)}
                  alt="icon_info"
                />
              </div>
              <div className="flex pt-4 mb-16 sm:mb-6 py-8 px-7 sm:py-3 text-base font-base text-darkslateblue">
                <Trans i18nKey="LP.calculator.info1"></Trans>
                <br />
                <br />
                <Trans i18nKey="LP.calculator.info2"></Trans>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCoCalculatorComponent;
