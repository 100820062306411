interface HomePageBulletInfoProps {
  imageUrl?: string;
  title: string;
  description: string;
  color: string;
}

const HomePageBulletInfoComponent: React.FunctionComponent<HomePageBulletInfoProps> = ({ imageUrl, title, description, color }) => {
  return (
    <div>
      <div className="flex gap-5 items-center justify-start sm:justify-center ">
        <img src={imageUrl} className="h-[2.188rem] w-auto" alt="Bullet" />
        <div className={`font-bold ${color} text-[1.125rem] mdSide:text-[1.375rem] lg:text-[1.375rem]`}>{title}</div>
      </div>
      <div className={`home-page-info-text text-center  mt-3 ${color}`}>{description}</div>
    </div>
  );
};

export default HomePageBulletInfoComponent;
