import { initialState, IState } from "./AppState";

let instance;

export default class AppContextService {

  private state: IState;

  constructor(actualState: IState) {
    this.state = actualState;
  }

  get(property: string) {
    return this.state[property];
  }

  set(property: string, value: any) {
    this.state[property] = value;
  }

  getState() {
    return this.state;
  }

  static getInstance(actualState?: IState): AppContextService {
    if (!instance) {
      instance = new AppContextService(actualState || initialState);
    }
    return instance;
  }
}