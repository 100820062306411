import React, { useState } from 'react';

interface SliderProps {
  min: number;
  max: number;
  initialValue: number;
  onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({ min, max, initialValue, onChange }) => {
  console.log('initialValue', initialValue);
  const [value, setValue] = useState(initialValue || 0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="w-full">
      <div className="flex justify-between text-sm mb-1">
        <span className="text-base mb-1 ml-2 text-darkslateblue">{min}</span>
        <span className="text-base mb-1 mr-2 text-darkslateblue">{max}</span>
      </div>
      <div className="flex items-center w-full">
        <input className="sliderCalculator" type="range" min={min} max={max} value={value} onChange={handleChange} />
      </div>
    </div>
  );
};

export default Slider;
