import React, { useEffect, useState } from 'react';
import './Imprint.css';
import AppContextService from '../../../context/AppContextService';
import { initialState } from '../../../context/AppState';
import FooterComponent from '../../common/footer/FooterComponent';
import MenuComponent from '../../common/menu/Menu';
import TopPageSwitcherComponent from '../home/TopPageSwitcher';
import { motion } from 'framer-motion';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';
import PageHolderComponent from '../home/PageHolder';
import { back_arrow } from '../../../assets/images';
interface PrivacyPolicyProps {
  onLanguageChange(lang: string): void;
}

const Imprint: React.FunctionComponent<PrivacyPolicyProps> = (props) => {
  const contextService = AppContextService.getInstance(initialState);
  const gdpr = contextService.get('gdpr');
  const [isSmallLG, setIsSmallLG] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const gdprExists = gdpr !== null;
  const lang = contextService.get('lang');
  const pageName = 'Imprint';
  const eventName = 'LP_ImprintPageVisited';

  function checkIsMobileView() {
    setIsMobileView(window.innerWidth < 1025); // Adjust the threshold value as needed
  }

  function checkIsSmallLG() {
    setIsSmallLG(window.innerWidth < 1440);
  }
  useEffect(() => {
    console.log(isSmallLG);
    AnalyticsService.track(eventName, {
      action: SA[SA.Viewed],
      page: pageName, 
    });
    const handleResize = () => {
      checkIsSmallLG();
      checkIsMobileView();
    };

    checkIsSmallLG();
    checkIsMobileView();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <motion.div className="overflow-x-hidden">
      <TopPageSwitcherComponent lang={lang}></TopPageSwitcherComponent>
      <PageHolderComponent>
        <a id="top"></a>
        <MenuComponent
          parentPage_linkId="imprint"
          parentPage={pageName}
          onLanguageChange={props.onLanguageChange}
          forceDarkColorLogo={true}
          useBlueBerryLogo={false}
          language={lang}
          showNotifyBox={false}
        ></MenuComponent>

        {lang === 'de' ? (
          <div className="flex flex-col mb-12">
            <div className="flex mt-12">
              <a href="/" className="flex" rel="noreferrer">
                <img src={back_arrow} />
                <span className="Back-to-Home">Zurück zur Startseite</span>
              </a>
            </div>
            <div className="pp-imprint-title mt-12">Impressum</div>
            <div className="pp-body mt-16">
              <div className="pp-text-block-title">Angaben gemäß § 5 TMG</div>
              Footprint Technologies GmbH
              <br /> Alt-Stralau 5b
              <br /> 10245 Berlin
              <br /> <br />
              Handelsregister: 209853 B
              <br />
              Registergericht: Amtsgericht Berlin-Charlottenburg
              <br />
              <strong>Vertreten durch:</strong>
              <br />
              Dr. Matthias Brendel, Muhammad Ali Kezze, Carolin Kleinert
              <div className="pp-imprint-title mt-12">Kontakt</div>
              <br />
              <strong>Telefon:</strong> +49 30 81457917
              <br />
              <strong>Telefax:</strong> +49 30 81457919
              <br />
              <strong>E-Mail:</strong>
              <a href="mailto:info@footprinttech.de" target="_blank" className="pp-text-block-link" rel="noreferrer">
                info@footprinttech.de
              </a>
              <div className="pp-imprint-title mt-12">Postadresse</div>
              <br />
              Kleine Präsidentenstraße 1 <br />
              10178 Berlin
              <br />
              <div className="pp-imprint-title mt-12">Umsatzsteuer-ID</div>
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a<br />
              Umsatzsteuergesetz: DE326018272
              <div className="pp-imprint-title mt-12">Angaben zur Berufs­haftpflicht­versicherung</div> <br />
              <strong>Name und Sitz des Versicherers:</strong> SIGNAL IDUNA Allgemeine Versicherung AG, Dortmund
              <br /> <br />
              <strong>Geltungsraum der Versicherung:</strong> Weltweit
              <br /> <div className="pp-imprint-title mt-12">Redaktionell verantwortlich</div>
              <br />
              Carolin Kleinert
              <br /> <div className="pp-imprint-title mt-12">EU-Streitschlichtung</div> <br />
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <br />
              <a href="https://ec.europa.eu/consumers/odr/" target="_blank" className="pp-text-block-link" rel="noreferrer">
                https://ec.europa.eu/consumers/odr/.
              </a>
              <br />
              Unsere E-Mail-Adresse finden Sie oben im Impressum. <br />
              <div className="pp-imprint-title mt-12">Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</div> <br />
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </div>
          </div>
        ) : (
          <div className="flex flex-col mb-12">
            <div className="flex mt-12">
              <a href="/" className="flex" rel="noreferrer">
                <img src={back_arrow} />
                <span className="Back-to-Home">Back to Home</span>
              </a>
            </div>
            <div className="pp-imprint-title mt-12">Site Notice</div>
            <div className="pp-body mt-16">
              <div className="pp-text-block-title">Information pursuant to Sect. 5 German Telemedia Act (TMG)</div>
              Footprint Technologies GmbH
              <br /> Alt-Stralau 5b
              <br /> 10245 Berlin
              <br /> <br />
              Commercial Register: 209853 B
              <br />
              Registration court: Amtsgericht Berlin-Charlottenburg
              <br />
              <strong>Represented by:</strong>
              <br />
              Dr. Matthias Brendel, Muhammad Ali Kezze, Carolin Kleinert
              <div className="pp-imprint-title mt-12">Contact</div>
              <br />
              <strong>Phone:</strong> +49 30 81457917
              <br />
              <strong>Telefax:</strong> +49 30 81457919
              <br />
              <strong>Email:</strong>
              <a href="mailto:info@footprinttech.de" className="pp-text-block-link" rel="noreferrer">
                info@footprinttech.de
              </a>
              <div className="pp-imprint-title mt-12">Postal address</div>
              <br />
              Kleine Präsidentenstraße 1 <br />
              10178 Berlin
              <br />
              <div className="pp-imprint-title mt-12">VAT ID</div> <br />
              Sales tax identification number according to Sect. 27 a of the Sales <br />
              Tax Law: DE326018272
              <div className="pp-imprint-title mt-12">Information regarding professional liability insurance</div> <br />
              <strong>Name and registered office of the insurer:</strong> SIGNAL IDUNA Allgemeine Versicherung AG, Dortmund
              <br /> <br />
              <strong>Scope of insurance:</strong> Worldwide
              <br /> <div className="pp-imprint-title mt-12">Person responsible for editorial</div>
              <br />
              Carolin Kleinert
              <br /> <div className="pp-imprint-title mt-12">EU dispute resolution</div> <br />
              The European Commission provides a platform for online dispute resolution (ODR): <br />
              <a href="https://ec.europa.eu/consumers/odr/" target="_blank" className="pp-text-block-link" rel="noreferrer">
                https://ec.europa.eu/consumers/odr/.
              </a>
              <br />
              Our e-mail address can be found above in the site notice. <br />
              <div className="pp-imprint-title mt-12">Dispute resolution proceedings in front of a consumer arbitration board</div> <br />
              We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.
            </div>
          </div>
        )}
        <div className="flex left-0 absolute w-full mt-12 items-start justify-start bg-[#1b1c1e]">
          <div className="flex w-full mdSide:py-12 lg:py-12  home-page-place-holder-margin ">
            <FooterComponent   parentPage={pageName} parentPage_linkId="imprint"></FooterComponent>
          </div>
        </div>
      </PageHolderComponent>
    </motion.div>
  );
};

export default Imprint;
