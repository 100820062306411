import React, { useEffect, useState, useRef } from 'react';
import './Menu.css';
import { LogoDark, LogoLight, close, LogoBlueBerry, red_dot } from '../../../assets/images';
import { Trans, useTranslation } from 'react-i18next';
import LanguageSwitchComponent from '../languageSwitcher/LanguageSwitcher';
import { AnalyticsService } from '../../../services/AnalyticsService';
import ButtonV2 from '../buttonv2/Buttonv2';
import { Tools } from '../../../services/Tools';
import { SA } from '../../../models/AState';
import BackendService from '../../../services/BackendService';

interface MenuProps {
  parentPage: string;
  parentPage_linkId: string;
  language: string;
  onLanguageChange(lang: string): void;
  forceDarkColorLogo: boolean;
  useBlueBerryLogo: boolean;
  showNotifyBox: boolean;
}

const MenuComponent: React.FunctionComponent<MenuProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const UMA_URL = process.env.REACT_APP_UMA_URL;
  const pageName = 'Navigation';
  const eventName = 'LP_NavigationVisited';

  const timerRef = useRef(null);
  const dummyAction = async () => {
    return null;
  };

  const onCloseMenu = () => {
    setMenuOpen(prevState => {
        if (prevState) {
            // enable scroll when menu is about to close
            document.body.style.overflow = "";
        } else {
            // disable scroll when menu is about to open
            document.body.style.overflow = "hidden";
        }
        return !prevState;
    });
};
useEffect(() => {
  // clean up function to enable scroll when component unmounts
  return () => {
      document.body.style.overflow = "";
  };
}, []);

  const takeAction = async (userAction, link_text, link_url) => { 
   
    if (props.parentPage_linkId !== userAction) {
      callPageClosed();
    }else{
      onCloseMenu();
    }
    AnalyticsService.track(eventName, {
      action: SA[SA.Clicked],
      navItem: userAction,     
      linkId: userAction,
      linkText: link_text,
      linkUrl: link_url,
      language: props.language,
    });
    return null;
  };

  const callPageClosed = () => {
    const timeSpent = (Date.now() - timerRef.current) / 1000;
    AnalyticsService.track(props.parentPage, {
      page: props.parentPage,
      action: SA[SA.Closed],
      duration: timeSpent,
      language: props.language,
    });
  };

  const handleLinkClick = async (e, url, action, link_text, isOut) => {
    e.preventDefault(); 
    
    await takeAction(action, link_text, url);
    if (isOut) {
      window.open(url, '_out');
    } else {
      window.location.href = url;
    }
  };
 
  useEffect(() => {
    timerRef.current = Date.now();
  }, []);
  return (
    <>
      <a id="top"></a>
      <div className="hidden mdSide:hidden lg:flex z-[20] w-full relative">
        <ul className="flex flex-row  gap-x-10  flex-nowrap text-lg font-medium  w-full gap-12 items-center align-middle text-darkslateblue">
          <li className="mr-10">
            <a onClick={(e) => handleLinkClick(e, '/', 'home_page', '',false)} style={{ cursor: 'pointer' }}>
              <img className="mdSlide:w-[3.544rem] mdSlide:h-[2.492rem] lg:w-[3.544rem] lg:h-[2.492rem]" src={LogoDark} alt="Get the correct shoe size for your chosen shoe with Footprint" />
            </a>
          </li>
          <li>
            <a
              onClick={(e) => handleLinkClick(e, '/Explore-Our-App#top', 'explore_app', t('menu.explore-our-app'),  false)}
              style={{ cursor: 'pointer' }}
              className="whitespace-nowrap"
            >
              <Trans i18nKey="LP.menu.explore-our-app"></Trans>
            </a>
          </li>
          <li>
            <a
              onClick={(e) => handleLinkClick(e, 'https://help.myfootprint.app/', 'helpcenter', t('menu.help-center'), true)}
              style={{ cursor: 'pointer' }}
              className="whitespace-nowrap"
            >
              <Trans i18nKey="LP.menu.help-center"></Trans>
            </a>
          </li>
          <li className="relative">
            <img src={red_dot} className="absolute right-[-0.8rem] top-[-0.3rem] w-[0.68rem]" alt="red_dot" />
            <a
              onClick={(e) => handleLinkClick(e, '/Shape-Footprint', 'create', t('menu.help-us-improve'), false)}
              style={{ cursor: 'pointer' }}
              className="whitespace-nowrap"
            >
              <Trans i18nKey="LP.menu.help-us-improve"></Trans>
            </a>
          </li>
          <li>
            <LanguageSwitchComponent lang={props.language} onLanguageChange={props.onLanguageChange} />
          </li>
        </ul>
     
      </div>
      {/* Mobile Version */}
      {menuOpen && (
        <div className="lg:hidden absolute top-0 bottom-0 left-0 w-full flex flex-col justify-between pl-[9%] pr-[9%] h-[100vh] bg-almond shadow z-50 pt-8 pb-32 landscape:pb-16">
          <div className="flex justify-between items-center portrait:mt-0 landscape:mt-2 ">
            <a href="/">
              <img src={LogoDark} className="w-[3.544rem] h-[2.492rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
            </a>
            <div className="flex items-center">
              <LanguageSwitchComponent lang={props.language} onLanguageChange={props.onLanguageChange} />
              <button
                type="button"
                className="cursor-pointer z-auto ml-12 mr-0 "
                onClick={() => {
                  onCloseMenu();
                }}
              >
                <img src={close} className="w-48" alt="close" />
              </button>
            </div>
          </div>
          <ul className="grid mdSide:grid-cols-2 landscape:grid-cols-2 flex-col gap-10 landscape:gap-6 text-darkslateblue text-lg">
            <li className="cursor-pointer mdSide:order-1">
              <a
                onClick={(e) => handleLinkClick(e, '/Explore-Our-App#top', 'explore_app', t('menu.explore-our-app'), false)}
                style={{ cursor: 'pointer' }}
                className="menu-link"
              >
                <Trans i18nKey="LP.link.how-it-works"></Trans>
              </a>
            </li>
            <li className="mdSide:order-3">
              <a className="menu-link" href="https://help.myfootprint.app/" target="_out">
                <Trans i18nKey="LP.link.help-center"></Trans>
              </a>
            </li>
            <li className="relative mdSide:order-5">
              <a className="menu-link items-center flex " onClick={(e) => handleLinkClick(e, '/Shape-Footprint', 'create', t('menu.help-us-improve'), false)}
              style={{ cursor: 'pointer' }}>
                <Trans i18nKey="LP.menu.help-us-improve"></Trans>
                <img src={red_dot} className="mb-4 ml-2" alt="red_dot" />
              </a>
            </li>
            <li className="mdSide:order-4">
              <a className="menu-link" onClick={(e) => handleLinkClick(e, '/imprint', 'imprint', t('link.imprint'), false)} style={{ cursor: 'pointer' }}>
                <Trans i18nKey="LP.link.imprint"></Trans>
              </a>
            </li>
            <li className="mdSide:order-6">
              <a className="menu-link" onClick={(e) => handleLinkClick(e, '/privacy-policy', 'privacy', t('link.privacy'), false)} style={{ cursor: 'pointer' }}>
                <Trans i18nKey="LP.link.privacy"></Trans>
              </a>
            </li>
          </ul>
   
        </div>
      )}
      {/* MD Version */}
      <div className="relative md:h-16 w-full z-40  mdSide:block md:block lg:hidden">
        <ul className="flex gap-6 items-center  text-sm text-darkslateblue">
          <li className="mr-auto">
            <a onClick={(e) => handleLinkClick(e, '/', 'home_page','', false)} style={{ cursor: 'pointer' }}>
              <img
                className="w-12"
                style={{ minWidth: '50px' }}
                src={props.forceDarkColorLogo ? (props.useBlueBerryLogo ? LogoBlueBerry : LogoDark) : LogoLight}
                alt="logo"
              />
            </a>
          </li>
          <li>
            <LanguageSwitchComponent lang={props.language} onLanguageChange={props.onLanguageChange} />
          </li>
        
          <li>
            {!menuOpen && (
              <div className="block space-y-3 cursor-pointer z-30" onClick={() => onCloseMenu()}>
                <div className="w-[2.25rem] h-0.5 bg-[#1e4e5f] rounded-sm"></div>
                <div className="w-[2.25rem] h-0.5 bg-[#1e4e5f] rounded-sm"></div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </>
  );
};

export default MenuComponent;
