import React, { useEffect, useState, useRef } from 'react';
import './ExploreApp.css';
import AppContextService from '../../../context/AppContextService';
import { initialState } from '../../../context/AppState';
import FooterComponent from '../../common/footer/FooterComponent';
import MenuComponent from '../../common/menu/Menu';
import TopPageSwitcherComponent from './TopPageSwitcher';
import { motion } from 'framer-motion';
import SliderComponent from './SliderComponent';
import HomePageClientCardPerfectFitComponent from './HomePageClientCardPerfectFit';
import {
  anchor,
  externalLinkIcon,
  heroDesktop,
  heroMobile,
  icon_check,
  icon_co2_header,
  icon_footwearH,
  icon_profiles,
  leaf_bullet,
  ricosta_perfectfit,
  baabuk_perfectfit,
  yuriStep1,
  yuriStep2,
  yuriStep3,
  yuriStepShoe,
} from '../../../assets/images';
import ExploreAppYuriComponent from './ExploreAppYuri';
import ExploreAppSectionTitleComponent from './ExploreAppSectionTitle';
import ButtonV2 from '../../common/buttonv2/Buttonv2';
import { Trans, useTranslation } from 'react-i18next';
import HomePageVideoSwitchv2 from './HomePageVideoSwitchV2';
import HomePageSectionTitleComponent from './HomePageSectionTitle';
import { AnalyticsService } from '../../../services/AnalyticsService';
import { SA } from '../../../models/AState';
import HomePageVideoSwitchMobile from './HomePageVideoSwitchMobile';

interface ExploreAppProps {
  onLanguageChange(lang: string): void;
}

const ExploreApp: React.FunctionComponent<ExploreAppProps> = (props) => {
  const contextService = AppContextService.getInstance(initialState);

  const [minHeightLG, setMinHeightLG] = useState(document.documentElement.clientWidth / 3);
  const [minHeightMD, setMinHeightMD] = useState(document.documentElement.clientWidth / 2.6);
  const [minHeightSM, setMinHeightSM] = useState(document.documentElement.clientWidth / 1.2);

  const gdpr = contextService.get('gdpr');
  const [isSmallLG, setIsSmallLG] = useState(false);
  const gdprExists = gdpr !== null;
  const lang = contextService.get('lang');
  const { t } = useTranslation();
  const pageName = 'Explore Page';
  const eventName = 'LP_ExplorePageVisited';

  const timerRef = useRef(null);

  const dummyAction = async () => {
    return null;
  };

  function checkIsSmallLG() {
    setIsSmallLG(window.innerWidth < 1440);
  }
  
  useEffect(() => {
    document.title = t`LP.pageTitle.ExploreApp`;
  });
  
  useEffect(() => {
    console.log(isSmallLG);
    AnalyticsService.track(eventName, {
      page: pageName,
      action: SA[SA.Viewed],
      language: lang,
    });
     
    const handleResize = () => {
      checkIsSmallLG();

      setMinHeightLG(document.documentElement.clientWidth / 2.8);
      setMinHeightMD(document.documentElement.clientWidth / 2.6);
      setMinHeightSM(document.documentElement.clientWidth / 1.3);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      const timeSpent = (Date.now() - timerRef.current) / 1000;
      AnalyticsService.track(eventName, {
        page: pageName,
        action: SA[SA.Completed],
        duration: timeSpent, 
      });
    };
  }, []);

  return (
    <motion.div className="overflow-x-hidden">
      <TopPageSwitcherComponent lang={lang}></TopPageSwitcherComponent>
      <div className="pt-16 explore-page-place-holder-margin">
        <a id="top"></a>
        <MenuComponent
          parentPage_linkId="explore_app"
          parentPage={eventName}
          onLanguageChange={props.onLanguageChange}
          forceDarkColorLogo={true}
          useBlueBerryLogo={false}
          language={lang}
          showNotifyBox={false}
        ></MenuComponent>
        <div className="left-0 hidden z-10 absolute w-full items-center justify-center pt-6 lg:block mdSide:block">
          <img src={heroDesktop} className="w-full " alt="Get the correct shoe size for your chosen shoe with Footprint" />
        </div>
        <div className="hidden md:block z-10   left-0 absolute items-center justify-center mt-10  w-full">
          <img src={heroDesktop} className="w-full " alt="Get the correct shoe size for your chosen shoe with Footprint" />
        </div>
        <div className="hidden sm:block left-0 z-10 absolute items-center justify-center mt-6 w-full">
          <img src={heroMobile} alt="Get the correct shoe size for your chosen shoe with Footprint" />
        </div>
        <div className="hidden mdSide:block lg:block" style={{ minHeight: minHeightLG }}></div>
        <div className="hidden md:block" style={{ minHeight: minHeightMD }}></div>
        <div className="hidden sm:block" style={{ minHeight: minHeightSM }}></div>

        {/* TAKE A LOOK */}
        <div className="flex flex-col gap-4 lg:gap-6 z-20">
          <div className="text-3xl lg:text-[2.625rem]  mdSide:text-[2.625rem]  w-1/2 text-darkslateblue  bg-almond  font-title tracking-wide  whitespace-nowrap">
            <b className="home-page-section-title-bold">
              <Trans i18nKey="LP.explore.takealook"></Trans>
            </b>
            <Trans i18nKey="LP.explore.atFootprint"></Trans>
          </div>
          <div className="text-[1.125rem] mdSide:text-[1.25rem] mdSide:w-2/3 lg:text-[1.375rem]  md:w-2/3 lg:w-2/3 text-darkslateblue self-start justify-start">
            <Trans i18nKey="LP.explore.measureFeet"></Trans>
          </div>
          <a href="#Details" className="md:mt-16 mdSide:mt-16 sm:mt-10 lg:mt-2 z-10">
            <ButtonV2 text={t`LP.explore.exploreApp`} size="large-text-button" enabled type="full" onClick={dummyAction}></ButtonV2>
          </a>
        </div>
        <div className="lg:mt-[-4.3rem] sm:hidden ">
          <div className="sm:hidden w-full">
            <div className="flex items-center justify-between mb-24">
              <div className="flex w-1/2 ">
                <div className="md:h-[40rem] lg:h-[46rem] mdSide:h-[36rem] flex justify-items-end ">
                  <div className="text-3xl mdSide:text-[3.125rem] lg:text-6xl md:text-[2.5rem] self-end  w-1/2 text-darkslateblue bg-almond font-title whitespace-nowrap">
                    <div>
                      <Trans i18nKey="LP.explore.shoeShopping"></Trans>
                    </div>
                    <b className="home-page-section-title-bold">
                      <Trans i18nKey="LP.explore.madeEasy"></Trans>
                    </b>
                  </div>
                </div>
                <div className="flex absolute left-0 pt-16 lg:hidden mdSide:hidden">
                  <a href="#Details" className=" pl-20 ">
                    <img src={anchor} className=" w-[2.938rem] " alt="anchor" />
                  </a>
                </div>
              </div>
              <div className="flex relative w-1/2 justify-center items-end md:items-start md:justify-start">
                <a href="#Details" className="absolute right-0 top-0 md:hidden">
                  <img src={anchor} className=" w-[2.938rem] " alt="anchor" />
                </a>
                <img src={yuriStepShoe} className="mdSide:mr-24 md:w-full mdSide:w-[21.566rem]  lg:w-[24.497rem] " alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
            </div>
          </div>
        </div>
        {/* MOBILE */}
        <div className="hidden sm:block mt-12 mb-12">
          <div className="flex flex-col  items-center justify-between">
            <div className="flex flex-col relative w-full h-[39.331rem] ">
              <div className="flex left-0 p-4 absolute">
                <a href="#Details" className=" left-0 p-18  md:hidden">
                  <img src={anchor} className=" w-[2.938rem] " alt="anchor" />
                </a>
              </div>
              <img src={yuriStepShoe} className="w-full" alt="Get the correct shoe size for your chosen shoe with Footprint" />
              <div className="relative top-48 text-3xl lg:hidden w-full  text-darkslateblue font-title whitespace-nowrap">
                <div>
                  <Trans i18nKey="LP.explore.shoeShopping"></Trans>
                </div>
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.explore.madeEasy"></Trans>
                </b>
              </div>
            </div>
          </div>
        </div>
        <a id="Details"></a>
        {/* PERFECT FIT */}
        <ExploreAppYuriComponent imageUrl={yuriStep1} imageAlign="left">
          <div className="self-center items-start mdSide:pl-24 md:pl-6  ">
            <ExploreAppSectionTitleComponent icon={icon_footwearH} title={t`LP.fit.perfect-fit`} />
            <div className="list flex flex-col gap-8 md:gap-2 lg:w-11/12 mdSide:w-11/12 md:w-12/12  md:leading-[1.31rem] mdSide:leading-[1.5rem]  w-full  text-darkslateblue">
              <div className="list-item">
                <span className="list-item-number font-bold">1.</span>
                <span className="list-item-text">
                  <Trans i18nKey="LP.explore.visitPartnerShops"></Trans>
                </span>
              </div>
              <div className="list-item">
                <span className="list-item-number">2.</span>
                <span className="list-item-text">
                  <Trans i18nKey="LP.explore.usingAI"></Trans>
                </span>
              </div>
              <div className="list-item">
                <span className="list-item-number">3.</span>
                <span className="list-item-text">
                  <Trans i18nKey="LP.explore.instantSize"></Trans>
                </span>
              </div>
            </div>
          </div>
        </ExploreAppYuriComponent>
        {/* MOBILE */}
        <div className="hidden sm:block mt-28">
          <div className="flex flex-col items-center justify-between">
            <div className="flex flex-col w-full">
              <ExploreAppSectionTitleComponent icon={icon_footwearH} title={t`LP.fit.perfect-fit`} />
              <div className="flex relative">
                <img src={yuriStep1} alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
              <div className="list flex flex-col gap-4  sm:leading-[1.31rem] mt-10 w-full text-darkslateblue">
                <div className="list-item">
                  <span className="list-item-number">1.</span> <Trans i18nKey="LP.explore.visitPartnerShops"></Trans>
                </div>
                <div className="list-item">
                  <span className="list-item-number">2.</span> <Trans i18nKey="LP.explore.usingAI"></Trans>
                </div>
                <div className="list-item">
                  <span className="list-item-number">3.</span>
                  <Trans i18nKey="LP.explore.instantSize"></Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CREATE PROFILES */}
        <ExploreAppYuriComponent imageUrl={yuriStep2} imageAlign="right">
          <div className="self-center  ">
            <ExploreAppSectionTitleComponent icon={icon_profiles} title={t`LP.explore.createProfiles`} />

            <div className="list flex flex-col gap-8 w-[26.188rem] text-darkslateblue">
              <div className="list-item md:w-9/12">
                <span className="list-item-number">
                  <img src={icon_check} className="w-[1.403rem]" alt="check" />
                </span>
                <Trans i18nKey="LP.explore.getInstantSize"></Trans>
              </div>
              <div className="list-item md:w-9/12">
                <span className="list-item-number">
                  <img src={icon_check} className="w-[1.403rem]" alt="check" />
                </span>
                <Trans i18nKey="LP.explore.profilesForFamily"></Trans>
              </div>
            </div>
          </div>
        </ExploreAppYuriComponent>
        {/* MOBILE */}
        <div className="hidden sm:block  mt-12">
          <div className="flex flex-col  items-center justify-between">
            <div className="flex flex-col w-full">
              <ExploreAppSectionTitleComponent icon={icon_profiles} title="Create profiles" />
              <div className="flex relative">
                <img src={yuriStep2} className="lg:w-[24.497rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
              <div className="list flex flex-col gap-4 mt-10 mb-10 w-full text-darkslateblue">
                <div className="list-item text-base">
                  <span className="list-item-number">
                    <img src={icon_check} className="w-[1.403rem] mr-4" alt="check" />
                  </span>
                  <Trans i18nKey="LP.explore.getInstantSize"></Trans>
                </div>
                <div className="list-item">
                  <span className="list-item-number">
                    <img src={icon_check} className="w-[1.403rem] mr-4" alt="check" />
                  </span>
                  <Trans i18nKey="LP.explore.profilesForFamily"></Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SUSTAINABILITY */}
        <ExploreAppYuriComponent imageUrl={yuriStep3} imageAlign="left">
          <div className="self-center  md:pl-6 ">
            <ExploreAppSectionTitleComponent icon={icon_co2_header} title={t`LP.explore.sustainably`} />
            <div className="list flex flex-col gap-8  text-darkslateblue">
              <div className="list-item ">
                <span className="list-item-number">
                  <img src={leaf_bullet} className="w-[0.966rem] mr-4" alt="bullet" />
                </span>
                <Trans i18nKey="LP.explore.commuteLess"></Trans>
              </div>
              <div className="list-item">
                <span className="list-item-number">
                  <img src={leaf_bullet} className="w-[0.966rem] mr-4" alt="bullet" />
                </span>
                <Trans i18nKey="LP.explore.reduceCOFootprint"></Trans>
              </div>
            </div>
          </div>
        </ExploreAppYuriComponent>
        {/* MOBILE */}
        <div className="hidden sm:block ">
          <div className="flex flex-col  items-center justify-between">
            <div className="flex flex-col w-full">
              <ExploreAppSectionTitleComponent icon={icon_co2_header} title={t`LP.explore.sustainably`} />
              <div className="flex relative">
                <img src={yuriStep3} className="lg:w-[24.497rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
              </div>
              <div className="list flex flex-col gap-8 mt-10 mb-10   text-darkslateblue">
                <div className="list-item">
                  <span className="list-item-number">
                    <img src={leaf_bullet} className="w-[0.966rem] mr-4" alt="bullet" />
                  </span>
                  <Trans i18nKey="LP.explore.commuteLess"></Trans>
                </div>
                <div className="list-item">
                  <span className="list-item-number">
                    <img src={leaf_bullet} className="w-[0.966rem] mr-4" alt="bullet" />
                  </span>
                  <Trans i18nKey="LP.explore.reduceCOFootprint"></Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* HOW TO MEASURE */}
        <a id="Videos"></a>
        <div className="sm:hidden md:hidden flex left-0  absolute w-full items-center sm:mt-12 justify-center bg-darkslateblue">
          <HomePageVideoSwitchv2 lang={lang} align="left" parentPage={pageName}/>
        </div>
        <div className="hidden sm:flex md:flex  left-0  absolute w-full items-center sm:mt-12 justify-center bg-darkslateblue">
          <HomePageVideoSwitchMobile lang={lang}  parentPage={pageName} />
        </div>
        <div className="sm:min-h-[36rem] md:min-h-[38rem] mdSide:min-h-[21rem] lg:min-h-[28rem]"></div>

        {/* FIND YOUR PERFECT FIT */}
        <div className="flex flex-col gap-12 py-20  sm:mt-12">
          <div className="hidden lg:block">
            <HomePageSectionTitleComponent textColor="text-darkslateblue">
              <Trans i18nKey="LP.find.your.perfect"></Trans>
              <b className="home-page-section-title-bold">
                <Trans i18nKey="LP.find.fit"></Trans>
              </b>
            </HomePageSectionTitleComponent>
            <div className="home-page-info-text text-center  text-darkslateblue mt-6 md:w-1/2">
              <Trans i18nKey="LP.find.fit.info"></Trans>
            </div>
          </div>
          <div className="lg:hidden flex flex-col">
            <HomePageSectionTitleComponent textColor="text-darkslateblue">
              <Trans i18nKey="LP.find.your.perfect"></Trans>
              <b className="home-page-section-title-bold">
                <Trans i18nKey="LP.find.fit"></Trans>
              </b>
            </HomePageSectionTitleComponent>
            <div className="home-page-info-text   text-darkslateblue mt-3 md:w-2/5 sm:w-3/4">
              <Trans i18nKey="LP.find.fit.info"></Trans>
            </div>
          </div>
          <div className="sm:ml-[-2rem] sm:mr-[-2rem] md:ml-[-6rem] md:mr-[-12rem] mdSide:ml-[-12rem] mdSide:mr-[-12rem] ">
            <SliderComponent>
              <HomePageClientCardPerfectFitComponent
                page={pageName}
                ctaValue="baabuk_sec"
                imageUrl={baabuk_perfectfit}
                externalLinkIcon={externalLinkIcon}
                targetUrl="https://www.baabuk.com/"
                title=""
                firstElement={true}
              />
              <HomePageClientCardPerfectFitComponent
                page={pageName}
                ctaValue="ricosta_sec"
                imageUrl={ricosta_perfectfit}
                externalLinkIcon={externalLinkIcon}
                targetUrl="https://www.ricosta.de/"
                title=""
              />
            </SliderComponent>
          </div>
        </div>

        <div className="flex left-0 absolute w-full items-start justify-start bg-[#1b1c1e]">
          <div className="flex w-full mdSide:py-12 lg:py-12  home-page-place-holder-margin ">
            <FooterComponent parentPage_linkId="explore_app" parentPage={eventName}></FooterComponent>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ExploreApp;
