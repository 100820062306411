interface HomePageWhiteCardProps {
  title: string;
  description: string;
  info: string;
  icon: string;
  hasCO2?: boolean;
}

const HomePageWhiteCardComponent: React.FunctionComponent<HomePageWhiteCardProps> = ({ title, icon, info, description, hasCO2 }) => {
  return (
    <div className="flex w-full ">
      <div className="flex flex-col lg:py-3 lg:px-12 w-full  mdSide:w-[29.125rem] lg:w-full lg:max-w-[35.75rem]  bg-white rounded-[2rem]  ">
        <div className="flex justify-between items-center pt-6 pb-4 px-7 lg:px-7 ">
          <div className="text-[0.875] md:text-[0.75] lg:text-xl text-darkslateblue">{title}</div>
          <img src={icon} className="w-[1.5rem] h-[1.5rem] lg:w-[2.188rem] lg:h-[2.188rem]" alt="Get the correct shoe size for your chosen shoe with Footprint" />
        </div>
        <div className="flex flex-col pt-0  pb-6 px-7  ">
          <div className="leading-[2rem] lg:leading-[3rem] pb-4 text-[2.188rem] lg:text-[3.813rem] text-darkslateblue font-bold">
            {info}
            {hasCO2 && (
              <span className="ml-4 text-sm lg:text-xl font-regular pt-3 font-thin font-['inter'] absolute">
                CO<sub>2</sub>
              </span>
            )}
          </div>
          <div className="text-[0.875rem]  lg:text-xl text-darkslateblue text-opacity-75 whitespace-nowrap">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default HomePageWhiteCardComponent;
