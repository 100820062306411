import App from './App'; 
import { AppContextProvider } from './context/AppContext';
import { createRoot } from 'react-dom/client';
import AppContextService from './context/AppContextService';
import { initReactI18next } from 'react-i18next';
import { setLanguage } from './services/Cookie';
import { catalogues, LanguageDetector } from '@footprint/common-components';
import { AnalyticsService } from './services/AnalyticsService';
import i18next from 'i18next';
import { SA } from './models/AState';
import { Helmet } from 'react-helmet';


const languageDetector = new LanguageDetector('en', 'lang');
let language = languageDetector.Detect().toLowerCase().split('-')[0];

const element = document.getElementById('root');
const root = createRoot(element!);
const contextService = AppContextService.getInstance();

const handleLanguageChange = (language: string) => {
  i18next.changeLanguage(language);
  window.localStorage.setItem('lang', language);
  contextService.set('lang', language);
  AnalyticsService.track('Navigation', {
    action: SA[SA.Clicked],
    navItem: language === 'de' ? 'lang_ger' : 'lang_eng',
    language: language,
  });
}; 

const i18nOptions = {
  lng: language!, 
  resources: {
    de: { translation: { ...catalogues.de} },
    en: { translation: { ...catalogues.en } },
  },
  fallbackLng: language === 'de' || language === 'en' ? language : 'en',
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
};

i18next.use(initReactI18next).init(i18nOptions);
handleLanguageChange(language);

root.render(
  <AppContextProvider>
        <Helmet>
      {language === 'en' && (
        <meta name="keywords" content="Footprint Shoe Sizing App, Smartphone Shoe Measurement, Accurate Shoe Sizing Tool, Online Shoe Size Recommendation, Digital Foot Measuring App, Perfect Fit Shoe App, Shoe Size Calculator App, Foot Measurement Software, Innovative Shoe Fitting App, AI Shoe Size Recommendation, Footprint Partner Shoe Stores, Online Foot Measurement Guide, Easy Shoe Shopping with Footprint, Advanced Shoe Size Estimation, Mobile Shoe Fitting Solution, Browser-based Shoe Sizing, Eco-friendly Shoe Shopping with Footprint, Footprint App for Better Shoe Fit, Find Your Shoe Size with Footprint, Footprint: Your Virtual Shoe Fitter, Custom Shoe Sizing with Footprint, Reduce Returns with Accurate Shoe Sizing, Tech-Forward Shoe Shopping Experience, AI-Powered Foot Measurement, Perfect Fit Every Time with Footprint, Seamless Shoe Shopping Experience, Shoe Size Finder App, Smart Shoe Shopping with Footprint, Personalized Shoe Fit with Footprint, Shoe Size Conversion App" />
      )}
      {language === 'en' && (
        <meta name="description" content="Shoe shopping made Easy. Visit one of our partner shops to find the shoe you’re looking for. Click on our Footprint button to start the measurement. Instantly get the correct shoe size for your chosen shoe." /> 
      )}
      {language === 'de' && (
        <meta name="keywords" content="Schuhgrößen App, Smartphone Schuhmessung, Online Schuhgrößen Empfehlung, Digitale Fußmessung App, Perfekt Passende Schuhe, Schuhgröße Rechner, Fußmessungssoftware, Innovative Schuhpassform App, KI Schuhgrößen Empfehlung, Footprint Partner Schuhgeschäfte, Online Fußmessung Anleitung, Einfacher Schuheinkauf, Fortgeschrittene Schuhgrößenschätzung, Mobile Schuhpassform Lösung, Browser-basierte Schuhgrößenbestimmung, Öko-freundlicher Schuheinkauf, Footprint App für Bessere Schuhpassform, Schuhgröße Finder App, Intelligenter Schuheinkauf, Personalisierte Schuhpassform, Schuhgröße Umrechnungs App" />
      )}
      {language === 'de' && (
        <meta name="description" content="Schuh-Shopping leicht gemacht. Besuche einen unserer Partnershops, um deinen nächsten Lieblingsschuh zu finden. Klicke auf den Footprint-Button, um eine Messung zu starten. Erhalte sofort die richtige Schuhgröße für deinen gewählten Schuh." /> 
      )}
      {/* Other meta tags */}
    </Helmet>
    <App onLanguageChange={handleLanguageChange} />
  </AppContextProvider>
);
