export interface AState {
  writeKey: string;
  analytics: any;
}

// Segment Action
export enum SA {
  Viewed,
  Clicked,
  Skipped,
  Submitted,
  Closed,
  Completed,
  Login,
  LoggedIn,
  VideoPlayed,
  VideoPaused,
  VideoSeeked,
  VideoStopped,
  Created,
  MeasurementProcessing,
  MeasurementProcessed,
  MeasurementSuccess,
  MeasurementCreated,
  MeasurementError,
  MeasurementExceptionError,
  ClickedBack,
  Link_Clicked,
}
