import React, { useEffect, useRef, useState } from 'react';
import './HomePage.css';
import { Trans, useTranslation } from 'react-i18next';
import HomePageSectionTitleComponent from './HomePageSectionTitle';
import { motion } from 'framer-motion';
import YouTube, { YouTubeProps } from 'react-youtube';
import { AnalyticsService } from '../../../services/AnalyticsService';

interface HomePageVideoSwitchProps {
  lang: string;
  parentPage:string;
  align?: string;
}

const HomePageVideoSwitchv2: React.FunctionComponent<HomePageVideoSwitchProps> = ({ lang, parentPage, align }) => {
  const [videoAgeGroup, setVideoAgeGroup] = useState('children');
  const [isSmallLG, setIsSmallLG] = useState(false);
  const playerRef1 = useRef(null);
  const playerRef2 = useRef(null);
  const playerRef3 = useRef(null);
  const playerRef4 = useRef(null);
  const { t } = useTranslation();
  const eventName = 'VideoInteraction';

  function setCenterOfScreenUpdate() {
    let centerX = window.innerWidth / 2;
    centerX = centerX * 0.84;
  }

  function checkIsSmallLG() {
    setIsSmallLG(window.innerWidth < 1440);
  }

  useEffect(() => {
    // AnalyticsService.track(pageName, {
    //   action: SA[SA.Viewed],
    //   language: lang,
    // });
    const handleResize = () => {
      checkIsSmallLG();
      setCenterOfScreenUpdate();
    };
    setCenterOfScreenUpdate();
    checkIsSmallLG();
    window.addEventListener('resize', handleResize);
    return () => { 
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const takeAction = async (userAction, video_id, pause_time) => {
    if (pause_time != null) {
      AnalyticsService.track(eventName, {
        action: userAction,
        videoId: video_id,
        page:parentPage,
        pauseTime: pause_time,
        channel: 'Landing Page',
      });
    } else {
      AnalyticsService.track(eventName, {
        action: userAction,
        videoId: video_id,
        page:parentPage,
        channel: 'Landing Page',
      });
    }
    return null;
  };

  const handlePlay = (ageGroup) => {
    takeAction('Played', ageGroup, null);
  };

  const handlePause = (ageGroup, pRef) => {
    const player = pRef.current.internalPlayer;
    player.getCurrentTime().then((currentTime) => {
      takeAction('Paused', ageGroup, currentTime);
    });
  };

  const handleEnd = (ageGroup) => {
    takeAction('Ended', ageGroup, null);
  };

  const switchVideo = async (ageGroup) => {
    const iframeC = document.getElementById('movieChild') as HTMLIFrameElement;
    const iframeA = document.getElementById('movieAdult') as HTMLIFrameElement;
    if (iframeC && iframeC.contentWindow) {
      iframeC.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
    if (iframeA && iframeA.contentWindow) {
      iframeA.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    setVideoAgeGroup(ageGroup);
    return null;
  };
  const tabs = [
    { name: t`LP.howto.children`, color: '#1e4e5f' },
    { name: t`LP.howto.adults`, color: '#1e4e5f' },
  ];
  const [selected, setSelected] = useState(0);
  const [formerColor, setFormerColor] = useState(tabs[0].color);

  return (
    <>
      {align === 'right' ? (
        <div className="flex w-full py-12 mdSide:py-12 lg:py-24  home-page-place-holder-margin mx-auto">
          <div className="w-full sd:flex-col md:flex-col lg:flex mdSide:flex ">
            <div className="flex flex-col w-full mx-auto sm:text-center md:text-center z-10">
              <HomePageSectionTitleComponent textColor="text-almond">
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.howto.title.1a"></Trans>
                </b>
                <Trans i18nKey="LP.howto.title.1b"></Trans>.
              </HomePageSectionTitleComponent>
              <div className="mt-4 mdSide:w-72 lg:w-96 text-almond lg:text-xl text-base font-normal  sm:text-center md:text-center">
                <Trans i18nKey="LP.howto.title.info"></Trans>
              </div>
              <div className="flex gap-12 md:justify-center">
                <div className="videoOptionsContainer md:w-[100%] mt-16 mdSide:mt-20 lg:mt-24">
                  {tabs.map(({ name, color }, i) => (
                    <motion.div
                      className="videoOptionsTab whitespace-nowrap"
                      key={i}
                      initial={{ opacity: 0.99, color: i === selected ? '#fdf5ec' : '#1e4e5f' }}
                      animate={{ opacity: 0.99, color: i === selected ? '#fdf5ec' : '#1e4e5f' }}
                      onTap={() => {
                        setFormerColor(tabs[selected].color);
                        setSelected(i);
                        switchVideo(i === 0 ? 'children' : 'adult');
                      }}
                    >
                      {name}
                      {i === selected && (
                        <motion.div
                          className="videoOptionSelection"
                          layoutId="selected"
                          style={{ borderRadius: 300 }}
                          initial={{ backgroundColor: formerColor, opacity: 0.99 }}
                          animate={{ backgroundColor: color, opacity: 0.99 }}
                          // transition={{ duration: 2 }}
                        />
                      )}
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{ position: 'relative' }}
              className="w-full lg:ml-24 lg:py-36 md:h-[16rem] md:mt-12 sm:h-[14rem] sm:mt-8 items-center justify-center"
            >
              <motion.div
                className="sm:w-[100%] md:w-[25.375rem] md:h-[16.063rem]  mdSide:w-[25.375rem] mdSide:h-[16.063rem] lg:w-full lg:h-[18.563rem] overflow-hidden h-full rounded-2xl"
                style={{ position: 'absolute', top: 0, left: 0 }}
                animate={videoAgeGroup === 'children' ? { x: 0, opacity: 100 } : { x: -1800, opacity: 0 }}
              >
                <YouTube
                  videoId={lang === 'en' ? 'oTMGyeoCqX8' : '-TAwALg6FkI'}
                  opts={{
                    width: '100%',
                    height: '100%',
                    playerVars: {
                      rel: 0,
                      showinfo: 0,
                    },
                  }}
                  ref={playerRef1}
                  className="w-full mx-auto h-full"
                  id="movieChild"
                  onReady={onPlayerReady}
                  onPlay={() => handlePlay('child')}
                  onPause={() => handlePause('child', playerRef1)}
                  onEnd={() => handleEnd('child')}
                />
              </motion.div>
              <motion.div
                className="sm:w-[100%]  md:w-[25.375rem] md:h-[16.063rem]  mdSide:w-[25.375rem] mdSide:h-[16.063rem] lg:w-full  lg:h-[18.563rem] overflow-hidden h-full rounded-2xl"
                style={{ position: 'absolute', top: 0, left: 0 }}
                animate={videoAgeGroup !== 'children' ? { x: 0, opacity: 100 } : { x: -1800, opacity: 0 }}
              >
                <YouTube
                  videoId={lang === 'en' ? 'jJhYU4XZSRQ' : 'nunpNaNH-s4'}
                  opts={{
                    width: '100%',
                    height: '100%',
                    playerVars: {
                      rel: 0,
                      showinfo: 0,
                    },
                  }}
                  ref={playerRef2}
                  className="w-full mx-auto h-full"
                  id="movieAdult"
                  onPlay={() => handlePlay('adult')}
                  onPause={() => handlePause('adult', playerRef2)}
                  onEnd={() => handleEnd('adult')}
                />
              </motion.div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full py-12 mdSide:py-12 lg:py-24 home-page-place-holder-margin mx-auto">
          <div className="w-full sd:flex-col md:flex-col lg:flex mdSide:flex  ">
            <div
              style={{ position: 'relative' }}
              className="w-full lg:ml-[-1rem] lg:py-36 md:h-[16rem] md:mt-12 sm:h-[14rem] sm:mt-8 items-center justify-center"
            >
              <motion.div
                className={`w-full sm:w-[21.375rem] sm:h-[13.375rem]  md:w-[25.375rem] md:h-[16.063rem]  mdSide:w-[25.375rem] mdSide:h-[16.063rem] lg:w-full lg:h-[${
                  isSmallLG ? '16rem' : '18.563rem'
                }] overflow-hidden h-full rounded-2xl`}
                style={{ position: 'absolute', top: 0, left: 0 }}
                animate={videoAgeGroup === 'children' ? { x: 0, opacity: 100 } : { x: -1800, opacity: 0 }}
              >
                <YouTube
                  videoId={lang === 'en' ? 'oTMGyeoCqX8' : '-TAwALg6FkI'}
                  opts={{
                    width: '100%',
                    height: '100%',
                    playerVars: {
                      rel: 0,
                      showinfo: 0,
                    },
                  }}
                  ref={playerRef3}
                  className="w-full mx-auto h-full"
                  id="movieChild"
                  onReady={onPlayerReady}
                  onPlay={() => handlePlay('child')}
                  onPause={() => handlePause('child', playerRef3)}
                  onEnd={() => handleEnd('child')}
                />
              </motion.div>
              <motion.div
                className={`w-full sm:w-[21.375rem] sm:h-[13.375rem]  md:w-[25.375rem] md:h-[16.063rem]  mdSide:w-[25.375rem] mdSide:h-[16.063rem] lg:w-full lg:h-[${
                  isSmallLG ? '16rem' : '18.563rem'
                }] overflow-hidden h-full rounded-2xl`}
                style={{ position: 'absolute', top: 0, left: 0 }}
                animate={videoAgeGroup !== 'children' ? { x: 0, opacity: 100 } : { x: -1800, opacity: 0 }}
              >
                <YouTube
                  videoId={lang === 'en' ? 'jJhYU4XZSRQ' : 'nunpNaNH-s4'}
                  opts={{
                    width: '100%',
                    height: '100%',
                    playerVars: {
                      rel: 0,
                      showinfo: 0,
                    },
                  }}
                  ref={playerRef4}
                  className="w-full mx-auto h-full"
                  id="movieAdult"
                  onPlay={() => handlePlay('adult')}
                  onPause={() => handlePause('adult', playerRef4)}
                  onEnd={() => handleEnd('adult')}
                />
              </motion.div>
            </div>
            <div className="flex flex-col w-full lg:ml-12 sm:text-center md:text-center z-10">
              <div className="lg:text-[3.125rem] text-[1.875rem]  text-almond font-title tracking-wide mb-8   whitespace-nowrap">
                <Trans i18nKey="LP.explore.footprintFor"></Trans>
                <b className="home-page-section-title-bold">
                  <Trans i18nKey="LP.explore.anyone"></Trans>
                </b>
              </div>
              <div className="flex gap-12 md:justify-center lg:mr-0">
                <div className="videoOptionsContainer  ml-4 mr-4 mt-16 mdSide:mt-20 lg:mt-24">
                  {tabs.map(({ name, color }, i) => (
                    <motion.div
                      className="videoOptionsTab whitespace-nowrap"
                      key={i}
                      initial={{ opacity: 0.99, color: i === selected ? '#fdf5ec' : '#1e4e5f' }}
                      animate={{ opacity: 0.99, color: i === selected ? '#fdf5ec' : '#1e4e5f' }}
                      onTap={() => {
                        setFormerColor(tabs[selected].color);
                        setSelected(i);
                        switchVideo(i === 0 ? 'children' : 'adult');
                      }}
                    >
                      {name}
                      {i === selected && (
                        <motion.div
                          className="videoOptionSelection"
                          layoutId="selected"
                          style={{ borderRadius: 300 }}
                          initial={{ backgroundColor: formerColor, opacity: 0.99 }}
                          animate={{ backgroundColor: color, opacity: 0.99 }}
                          // transition={{ duration: 2 }}
                        />
                      )}
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default HomePageVideoSwitchv2;
