import { useTranslation } from 'react-i18next';
import { arrowLink } from '../../../assets/images';
import ButtonV2 from '../../common/buttonv2/Buttonv2';
import { AnalyticsService } from '../../../services/AnalyticsService';

interface ClientCardProps {
  page: string;
  ctaValue: string;
  imageUrl?: string;
  imageUrlMobile?: string;
  clientLogoUrl?: string;
  title: string;
  description: string;
  targetUrl: string;
  firstElement?: boolean;
}

const takeAction = async (pageName, ctaValue) => {
  AnalyticsService.track(pageName, {
    action: 'CTA',
    ctaId: ctaValue,
  });
  return null;
};

const openExternalUrl = async (targetUrl) => {
  window.open(targetUrl, '_blank');
  return null;
};

const handleLinkClick = (event, pageName, ctaValue, url) => {
  event.preventDefault();
  takeAction(pageName, ctaValue);
  openExternalUrl(url);
};

const HomePageClientCardComponent: React.FunctionComponent<ClientCardProps> = ({
  page,
  ctaValue,
  imageUrl,
  imageUrlMobile,
  title,
  description,
  targetUrl,
  firstElement,
}) => {
  const { t } = useTranslation();

  return (
    <>
    <a style={{cursor:'pointer'}} onClick={(event) => handleLinkClick(event, page, ctaValue, targetUrl)}
  >
      <div
        className={`hidden pr-12 md:pl-8 ${
          firstElement === true ? ' mdSide:pl-32' : 'mdSide:pl-2'
        } mdSide:flex lg:flex flex-col snap-start`}
      >
        <div>
          <img className="w-[22rem]" src={imageUrl} alt={title} />
        </div>
        <div className="flex flex-col p-6 w-[22rem] bg-white rounded-b-3xl  ">
          <div className="font-bold text-4xl font-['Rubik']  text-darkslateblue">{title}</div>
          <div className="text-darkslateblue font-regular text-lg mt-4 min-h-[8rem]">{description}</div>
          <div className="flex md:hidden mdSide:hidden justify-end mt-4">
            <div>
                <img src={arrowLink} className="w-[3.625rem]" alt="arrow" />
             
            </div>
          </div>
        </div>
      </div> </a>
      {/* TABLET CARD */}
      <a 
        style={{cursor:'pointer'}}  
        onClick={(event) => handleLinkClick(event, page, ctaValue, targetUrl)}
      >
        <div className={`hidden md:flex pl-12  md:pr-12 ${firstElement === true ? ' md:pl-16' : 'md:pl-2'}  flex-col snap-start`}>
          <div>
            <img className="w-[22rem]" src={imageUrl} alt={title} />
          </div>
          <div className="flex flex-col p-6 w-[22rem] h-[16.688rem]  bg-white rounded-b-3xl  ">
            <div className="font-bold text-4xl font-['Rubik']  text-darkslateblue">{title}</div>
            <div className="text-darkslateblue font-regular text-lg min-h-[7rem] mt-4">{description}</div>
            <div className="flex md:hidden mdSide:hidden justify-end mt-4">
              <div>
                <img src={arrowLink} className="w-[3.625rem]" alt="arrow" />
              </div>
            </div>
          </div>
        </div>
      </a>
      {/* MOBILE CARD */}
      <a  style={{cursor:'pointer'}}  onClick={(event) => handleLinkClick(event, page, ctaValue, targetUrl)}>
        <div className="sm md:hidden mdSide:hidden lg:hidden snap-start pl-8 ">
          <div className="flex flex-col p-6 w-full bg-white rounded-t-3xl gap-4 items-center justify-center ">
            <div className="font-bold font-['Rubik'] text-4xl text-darkslateblue">{title}</div>
            <div className="text-darkslateblue font-regular mt-4 text-center">{description}</div>
            <ButtonV2
              text={t`LP.findus.to-partner-shops`}
              size="large-button"
              enabled
              type="full"
              onClick={() => {
              }}
            ></ButtonV2>
          </div>
          <div className="w-[17rem]">
            <img className="w-[full]" src={imageUrlMobile} alt={title} />
          </div>
        </div>
      </a>
    </>
  );
};

export default HomePageClientCardComponent;
