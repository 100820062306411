import { ReactNode } from 'react';

interface HomePageSectionTitleProps {
  textColor: string;
  children: ReactNode;
}

const HomePageSectionTitleComponent: React.FunctionComponent<HomePageSectionTitleProps> = ({ children, textColor }) => {
  return <div className={`text-3xl leading-none lg:text-5xxl ${textColor} font-title tracking-wide  whitespace-nowrap`}>{children}</div>;
};

export default HomePageSectionTitleComponent;
