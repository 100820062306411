import { icon_quote } from '../../../assets/images';

interface HomePageCarouselCardProps {
  title: string;
  description: string;
  sign: string;
}

const HomePageCarouselCardComponent: React.FunctionComponent<HomePageCarouselCardProps> = ({ title, sign, description }) => {
  return (
    <div className="flex w-full">
      <div className="relative flex flex-col mt-6 p-6 w-[18.5rem] items-center bg-white rounded-3xl pt-16 pb-8 ">
        <div className="absolute top-0 flex justify-between -translate-y-1/2">
          <img src={icon_quote} alt="quote" />
        </div>
        <div className="flex flex-col gap-2 ">
          <div className="text-sm text-darkslateblue">
            “ <span className="font-bold">{title}</span>
          </div>
          <div className="text-sm text-darkslateblue">{description} ”</div>
          <div className="text-sm text-darkslateblue font-bold mt-8 text-center">{sign}</div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCarouselCardComponent;
