import { ReactNode } from 'react';

interface HomePageSectionTitleProps {
  textColor: string;
  children: ReactNode;
}

const HomePageSectionTitleAltComponent: React.FunctionComponent<HomePageSectionTitleProps> = ({ children, textColor }) => {
  return <div className={`text-2xl md:text-4xl lg:text-4xl  ${textColor}  font-title tracking-wide  whitespace-nowrap`}>{children}</div>;
};

export default HomePageSectionTitleAltComponent;
